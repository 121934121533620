import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../service/api.service';

@Injectable()
export class UserInterceptor implements HttpInterceptor {

  constructor(private authentication: ApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   const currentUser = this.authentication.currentUserValue();
   if(currentUser && currentUser.authdata) {
     request = request.clone({
       setHeaders: {
         Authorization: `Basic ${currentUser.authdata}`
       }
     })
   }

    return next.handle(request);
  }
}
export const JwtInterceptorProvider = { provide : HTTP_INTERCEPTORS, multi: true}