<br>
<br>
<br>
<br>
<br>
<br>
<br>
<div class="login_modal-wrapper">
    <mat-spinner *ngIf="spinner" class="spinner"></mat-spinner>
    <div *ngIf="!forgotCheck && !registered && !spinner" class="login_modal-inner">
        
        <div class="login_modal-header text-center">
            <h3 class="login_modal-title">Sign in</h3>
        </div>
        <div class="login_modal-form">
            <form class="form" name="loginForm" [formGroup]="loginForm">
                <div class="form-group">
                    <mat-form-field class="form-control">
                        <input matInput formControlName="email" placeholder="Email address">
                    </mat-form-field>
                    <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)"
                        class="alert alert-danger">
                        Email is required.
                    </div>
                </div>
                <div class="form-group">
                    <mat-form-field class="form-control">
                        <input matInput type="password" formControlName="pswrd" placeholder="Password">
                    </mat-form-field>
                    <div *ngIf="loginForm.controls['pswrd'].invalid && (loginForm.controls['pswrd'].dirty || loginForm.controls['pswrd'].touched)"
                        class="alert alert-danger">
                        Password is required.
                    </div>
                </div>
                <button type="submit" class="btn btn-primary login_btn" (click)="login()">Sign in</button>
                <!-- <button type="submit" class="btn btn-primary login_btn" (click)="register()">Sign up</button> -->

            </form>
            <span class="forgot" (click)="change()">Forgot Password?</span>
            <p class="login_info">If you need any support, please contact <br> <a href="mailto:info@test.com">info@test.com</a></p>
        </div>

    </div>
    <div *ngIf="forgotCheck" class="login_modal-inner">
        <div class="login_modal-header text-center">
            <a href="/"><img src="assets/images/saleslab_final_logo.png" height="60px"></a>
            <span class="splash-description">Forgot Password.</span>
        </div>
        <div class="login_modal-form">
            <form class="form" name="ForgotPswrdForm" [formGroup]="ForgotPswrdForm">
                <div class="form-group">
                    <mat-form-field class="form-control">
                        <input matInput formControlName="forgotEmail" placeholder="Email address">
                    </mat-form-field>
                    <div *ngIf="ForgotPswrdForm.controls['forgotEmail'].errors?.required && ForgotPswrdForm.controls['forgotEmail'].touched"
                        class="alert alert-danger">
                        Email is required.
                    </div>
                    <div *ngIf="ForgotPswrdForm.controls['forgotEmail'].errors?.email" class="alert alert-danger">Email Incorrect</div>
                </div>
                <button [disabled]="disable" ng-disabled="disableButton" type="submit" class="btn btn-primary btn-lg btn-block"
                    (click)="forgotPassword()">Send Link</button>
            </form>
        </div>

    </div>

    <!-- <div *ngIf="showUpload" class="overlay">
        <div class="popup">
           
            <div class="form">
     <p>We have Sent you The mail You can Click on The given Link and Reset Your Password</p>
        
                    <div class="button_group">
                        <button type="submit" mat-raised-button color="secondary" (click)="cancelPlan()" >OK</button>
                    </div>
    
               
            </div>
        </div>
    </div> -->
    
<!-- 
<div *ngIf="registered">
    <div class="login_modal-header text-center">
        <h3 class="login_modal-title">sign up</h3>
    </div>
    <div class="login_modal-form">
        <form class="form" name="registerForm" [formGroup]="registerForm">
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="email" placeholder="Email address">
                </mat-form-field>
                <div *ngIf="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)"
                    class="alert alert-danger">
                    Email is required.
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="password" placeholder="Password">
                </mat-form-field>
                <div *ngIf="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)"
                    class="alert alert-danger">
                    Password is required.
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="firstName" placeholder="First Name">
                </mat-form-field>
                <div *ngIf="registerForm.controls['firstName'].invalid && (registerForm.controls['firstName'].dirty || registerForm.controls['firstName'].touched)"
                    class="alert alert-danger">
                    FirstName is required.
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="lastname" placeholder="Last Name">
                </mat-form-field>
                <div *ngIf="registerForm.controls['lastname'].invalid && (registerForm.controls['lastname'].dirty || registerForm.controls['lastname'].touched)"
                    class="alert alert-danger">
                    LastName is required
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="contact" placeholder="Contact">
                </mat-form-field>
                <div *ngIf="registerForm.controls['contact'].invalid && (registerForm.controls['contact'].dirty || registerForm.controls['contact'].touched)"
                    class="alert alert-danger">
                    Contact is required.
                </div>
            </div>
             <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="email" placeholder="Email address">
                </mat-form-field>
                <div *ngIf="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)"
                    class="alert alert-danger">
                    Email is required.
                </div>
            </div> 
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="type" placeholder="User Type">
                </mat-form-field>
                <div *ngIf="registerForm.controls['type'].invalid && (registerForm.controls['type'].dirty || registerForm.controls['type'].touched)"
                    class="alert alert-danger">
                    Type is required.
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="organisation" placeholder="Organisation">
                </mat-form-field>
                <div *ngIf="registerForm.controls['organisation'].invalid && (registerForm.controls['organisation'].dirty || registerForm.controls['organisation'].touched)"
                    class="alert alert-danger">
                    Organisation is required.
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="form-control">
                    <input matInput formControlName="reportsTo" placeholder="Reports To">
                </mat-form-field>
                <div *ngIf="registerForm.controls['reportsTo'].invalid && (registerForm.controls['reportsTo'].dirty || registerForm.controls['reportsTo'].touched)"
                    class="alert alert-danger">
                    to report is required.
                </div>
            </div>
            <button type="submit" class="btn btn-primary login_btn" (click)="signup()">Sign up</button>

        </form>
    </div>
</div>
 -->

</div>