import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root',
})
export class CensusService {
  public Base_Url = environment.BaseURL;
  constructor(private httpClient: HttpClient) {}

  getCensusData() {
    return this.httpClient
      .get<censusFormat>(this.Base_Url + '/census', {
        headers: headers,
      })
      .toPromise();
  }
}

export interface censusFormat {
  avgAge: number;
  categories: [];
  census: string;
  nationalities: [];
  org: { _id: string; name: string };
  totalMembers: number;
  userId: { _id: string; firstName: string; lastName: string };
  __v: number;
  _id: string;
}
