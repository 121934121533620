import * as CryptoJS from 'crypto-js';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';

import { AccountService } from '../Modules/account/account.service';
import { AnalyticService } from '../analytic/analytic.service';
import { ApiService } from '../service/api.service';
import { AuthGuard } from '../guards/auth.guard';
// import { Router } from '@angular/router';
// import { CustomersService } from '../Components/customer-details/customers.service';
import { AuthenticationService } from '../services/authentication.service';
import { CustomersService } from '../Components/customer-details/customers.service';
import { Location } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    forgotCheck = false
    loginForm: FormGroup;
    submitted = false;

    returnUrl: string = '/user';
    error: string = '';
    ForgotPswrdForm: FormGroup;
    registerForm: FormGroup
    registered = false;
    errorMessage: string = "";
    spinner = false;
    showUpload = false;
    disable = false;
    constructor(private fb: FormBuilder, private custServ: CustomersService,
          private apiService: ApiService, private route: ActivatedRoute,
           private router: Router, private authenticationService: AuthenticationService,
            private accServe: AccountService,  private location: Location, private toaster: ToastrService) {
        this.loginForm = this.fb.group({
            email: new FormControl((''), [Validators.required, Validators.email]),
            pswrd: new FormControl((''), Validators.required)
        })
        this.ForgotPswrdForm = this.fb.group({
            forgotEmail: ['', Validators.compose([Validators.required, Validators.email])]
        })
        this.registerForm = this.fb.group({
            email: new FormControl((''), Validators.required),
            password: new FormControl((''), Validators.required),
            firstName: new FormControl((''), Validators.required),
            lastname: new FormControl((''), Validators.required),
            contact: new FormControl((''), Validators.required),
            type: new FormControl((''), Validators.required),
            organisation: new FormControl((''), Validators.required),
            reportsTo: new FormControl((''), Validators.required)
        })
        this.apiService.logout();
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    }

    ngOnInit(): void {
        this.spinner = false;
        if (localStorage.getItem('USER')) {
            this.router.navigate(['/dashboard']);
            return;
        }
        this.custServ.login.next(true);
        if (this.forgotCheck == false) {
            this.location.replaceState('/login');
        }

    }
    login() {
        if (this.loginForm.invalid) {

            return;
        }
        this.spinner = true;
        let loginDto: LoginDto = {
            email: this.loginForm.controls['email'].value,
            password: this.apiService.encryptUsingAES256(this.loginForm.controls['pswrd'].value)
        };
        this.accServe.getData(loginDto);
        this.authenticationService.login(loginDto)
        .subscribe(
            (data: any) => {
                this.spinner = false;
                if (data.status == 200 || data.status == 201) {
                    this.router.navigate(['/verification']);
                    this.custServ.routes.next(false)
                }
            },
            (err) => {
                this.spinner = false;
                this.toaster.error('Incorrect Email or Password. Please try again.')

            });
    }



    get f() { return this.loginForm.controls }
    get r() { return this.ForgotPswrdForm.controls }
    get s() { return this.registerForm.controls }
    // login() {
    //     this.submitted = true;
    //     if(this.loginForm.invalid) {
    //         return;
    //     }

    // this.apiService.login(this.f.email.value, this.f.pswrd.value).subscribe(res => {

    //     if(res) {
    //         this.router.navigate(["/home"]);

    //         this.error = '';
    //         console.log("res", res);
    //     }



    // //     if(res) {
    // // }
    // }, err => {
    //     console.log("error", err);
    //      this.router.navigate(['/'])
    //     this.error = err;
    // })

    //  }

    change() {
        this.forgotCheck = true;
        this.location.replaceState('/forgotpassword');

    }
    forgotPassword() {
        let email: email = this.ForgotPswrdForm.controls['forgotEmail'].value;
        this.authenticationService.forgotPasswordMail(email).subscribe(res => {
           if(res){
            // this.router.navigate(['/resetPassword'])  
           this.showUpload = true;
           this.disable = true;
           }
            
        })
          
    }
    cancelPlan(){
        this.showUpload = false;
    }


    register() {
        this.registered = true;


    }
    signup() {

        this.apiService.register(this.registerForm.value)
    }





}
export interface LoginDto {
    email: string;
    password: string;
    token?: string;
}

export interface email {
    email: string;
}