<br>
<br>
<br><br>
<header class="siteHeader">
    <div class="container">
        <ul class="navbar nav headerTop justify-content-center justify-content-sm-end px-0">
            <li class="nav-item">
                <a class="nav-link text-secondary" href="/login" title="Sign in"><small>Sign in</small></a>
            </li>
            <li class="nav-item active">
                <a class="nav-link text-secondary" href="/about" title="About Us"><small>About Us</small></a>
            </li>
        </ul>


    </div>
</header>

<section class="ourClientSay secPadd">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="testimonialsWrapper">
                    <div>
                        <div class="testimonialItem">
                            <div class="container-fluid">
                                <div class="row align-items-center">
                                    <div class="col-12 col-lg-6">
                                        <h1 class="big-title">
                                            SalesLabb in a nutshell
                                        </h1>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="clientInfo">
                                            <div class="clientMessage">
                                                <p class="description-big">
                                                    SalesLabb is built by salespeople for the salesperson. It is a
                                                    software designed to create more meaningful relationships through
                                                    transparency, automated systems, and simplified processes. We thrive
                                                    on finding new ways to help advisors find the best solutions for
                                                    their customers. Through creativity and motivation to create change,
                                                    we pave the way on how insurance is done.
                                                </p>
                                                <p class="description-big">
                                                    SalesLabb is simple and intuitive, allowing for any advisor to use
                                                    the platform with ease..
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="p-0">
    <img src="assets/images/about-us-background.jpg" class="w-100">
</section>