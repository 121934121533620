<div class="trends">
  <div class="dates_wrapper-outer">
    <h1>Trends Chart</h1>
    <div class="dates_wrapper">
      <mat-form-field appearance="fill">
        <mat-label>Select Type</mat-label>
        <mat-select [(ngModel)]="type" (ngModelChange)="enableFilter()">
          <mat-option [value]="'insurers'">Insurers</mat-option>
          <mat-option [value]="'organisations'">Organisations</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="type == 'insurers'">
        <mat-form-field appearance="fill">
          <mat-label>Select Insurers</mat-label>
          <mat-select multiple [(ngModel)]="selectedInsurers">
            <mat-option (click)="changeInsurer('all')" [value]="'all'"
              >All</mat-option
            >

            <mat-option
              *ngFor="let item of insurers"
              (click)="changeInsurer(item)"
              [value]="item"
            >
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="type == 'organisations'">
        <mat-form-field appearance="fill">
          <mat-label>Select Organisations</mat-label>
          <mat-select multiple [(ngModel)]="selectedOrganisations">
            <mat-option (click)="changeOrg('all')" [value]="'all'"
              >All</mat-option
            >
            <mat-option
              *ngFor="let item of organisations"
              (click)="changeOrg(item)"
              [value]="item"
            >
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Select Timeline</mat-label>
        <mat-select
          [(value)]="selectedTimeline"
          (selectionChange)="enableFilter()"
        >
          <mat-option *ngFor="let item of timeline" [value]="item">{{
            item
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="dates" *ngIf="selectedTimeline == 'custom'">
        <div id="startDate">
          <mat-form-field appearance="fill" class="filter-select-box">
            <mat-label>From Date</mat-label>
            <input
              matInput
              [matDatepicker]="frompicker"
              [max]="todayDate"
              [(ngModel)]="fromDate"
              readonly="readonly"
              (ngModelChange)="enableFilter()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="frompicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #frompicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div id="endDate">
          <mat-form-field appearance="fill" class="filter-select-box">
            <mat-label>To Date</mat-label>
            <input
              matInput
              [matDatepicker]="topicker"
              [max]="todayDate"
              [(ngModel)]="toDate"
              readonly="readonly"
              (ngModelChange)="enableFilter()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="topicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #topicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <button
        class="filterBtn"
        mat-button
        (click)="setFilter()"
        [disabled]="disableFilter"
      >
        Filter
      </button>
    </div>
  </div>
  <div class="graph_wg-wrapper">
    <div class="graph_wg-inner">
      <canvas
        baseChart
        [width]="barChartWidth"
        [height]="barChartHeight"
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType"
      >
      </canvas>
    </div>
  </div>
  <div class="loader" *ngIf="loading">
    <mat-progress-spinner [color]="color" [mode]="mode"> </mat-progress-spinner>
  </div>
</div>
