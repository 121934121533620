import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Users } from './user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  id!: string;
  organisationModel: any;
  constructor(private http: HttpClient,private toastr: ToastrService) { }

  private userList: Users[] = [];
  public reportdata: any = '';
  public closeBtn = new Subject<boolean>();
  public enableRport = new Subject<boolean>();
  public userReset = new BehaviorSubject(false);
  public orgdata: string = '';
  public Base_Url = environment.BaseURL;
  private headers = new HttpHeaders().append('content-type', 'application/json');
  public baseUrl = environment.BaseURL;


  getUser() {
    return this.http.get<Users>(this.Base_Url + '/users').pipe(
      map((data: Users) => {
        return data;
      }),catchError( (err) => {
        return err;
      })
    );
  }


  sendFormData(data: Users) {
    return this.http.post(this.Base_Url + '/users', data, { 'headers': this.headers }).subscribe((res:any) => {
      
      if (res && res.status) {
        this.toastr.success('User Created!!');
        this.userReset.next(true);
      }
      else
      {
        this.toastr.error(res.message);
      }
    })
  }

  getFormData() {
    return this.userList;

  }

  sendReport(data: any) {
    this.reportdata = data;
  }

  getUserList(id: string): Users | undefined {
    return this.userList.find(x => x.id == id);
  }

  getReport() {
    return this.reportdata;
  }

  updateuser(data: Users) {
    const body = JSON.stringify(data);
    return this.http.put(this.Base_Url + '/users/admin/' + data._id, body, { 'headers': this.headers }).subscribe((res:any) => {
      if (res && res.status){ 
        this.toastr.success('User updated!!');
      this.userReset.next(true);
      }
      else
      {
        this.toastr.error(res.message);

      }
    })

  }
  updateStatus(data:Users){
    const body = JSON.stringify(data);
    data.status = !data.status;
    return this.http.post(this.Base_Url + '/users/changestatus',data, { 'headers': this.headers }).subscribe((res:any) => {
      if (res){ 
        this.toastr.success('User status updated!!');
      this.userReset.next(true);
      }
      else
      {
        this.toastr.error(res.message);

      }
    })
  }

  deleteOrg(id: Users) {
    return this.http.delete(this.Base_Url + '/users/' + id).subscribe(res => {
    })
  }

  deactiveUsers(user: Users) {
    this.reportdata = user.reportsTo
    this.http.post(this.Base_Url + '/users/changeStatus', user, { 'headers': this.headers }).subscribe(res => {
    })
  }
  resetPassword(data:Users){  
    const body = {'email':data.email,'name':data.firstName}
    return this.http.post(this.Base_Url + '/users/forgotPassword', body, { 'headers': this.headers }).subscribe((res:any) => {
      if (res && res.status){ 
        this.toastr.success('Reset link have been sent to user email id"!!');
      // this.userReset.next(true);
      }
      else
      {
        this.toastr.error(res);

      }
    })
  }



}

