import { Component, OnInit } from '@angular/core';
import { CustomersService } from './customers.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  constructor(private customerServ: CustomersService) { 
    this.elementType = "img";
    this.level = "M";
    this.qrdata = "https://saleslabb.com";
    this.scale = 1;
    this.width = 256;
  }
  customers:any;
  ngOnInit(): void {
    this.getCustomerData();
  }



  public qrdata: string;
  public elementType: "img" | "url" | "canvas" | "svg";
  public level: "L" | "M" | "Q" | "H";
  public scale: number;
  public width: number;


  changeElementType(newValue: "img" | "url" | "canvas" | "svg"): void {
    this.elementType = newValue;
  }

  changeLevel(newValue: "L" | "M" | "Q" | "H"): void {
    this.level = newValue;
  }

  changeQrdata(newValue: string): void {
    this.qrdata = newValue;
  }

  changeScale(newValue: number): void {
    this.scale = newValue;
  }

  changeWidth(newValue: number): void {
    this.width = newValue;
  }
  getCustomerData(){
    this.customerServ.fetchDataAsPromise()
      .then((data) => {
        this.customers=data;

      })
      .catch((error) => {
        console.log("Promise rejected with " + JSON.stringify(error));
      });
  }
}
