import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from '@angular/core';

const headers= new HttpHeaders()
.set('content-type', 'application/json')
.set('Access-Control-Allow-Origin', '*');
@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  private spinnerObs$: BehaviorSubject<boolean>;
  private logoObs$: BehaviorSubject<boolean>;
  public getInfo: Subject<boolean>;
  public login: Subject<boolean>
  public routes: Subject<boolean>
  constructor(private httpClient: HttpClient) { 
    this.spinnerObs$ = new BehaviorSubject<boolean>(false);
    this.getInfo = new Subject();
    this.login = new Subject();
    this.logoObs$=new BehaviorSubject<boolean>(localStorage.getItem('USER')? true:false);

    this.routes = new Subject();
  }


  fetchDataAsPromise() {
    return this.httpClient
    .get(
        '/api/policy-details',{'headers':headers}
      )
      .toPromise();
  }

  getSpinnerInfo(): Observable<boolean> {
    return this.spinnerObs$.asObservable();
  }
  setSpinnerInfo(value:boolean){
    this.spinnerObs$.next(value);
  }
  getLogoValue(): Observable<boolean> {
    return this.logoObs$.asObservable();
  }
  setLogoValue(value:boolean){
    if(localStorage.getItem('USER')){
      this.logoObs$.next(true);
    }else{
    this.logoObs$.next(value);
    }
  }
  

}
