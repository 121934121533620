import * as CryptoJS from 'crypto-js'

import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../Authenticate/user';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
private currentUserSubject : BehaviorSubject<User>;
public currentUser :Observable<User>;
public baseUrl =environment.baseurl;
private headers = new HttpHeaders().append('content-type', 'applocation/json');

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
   }

   encryptUsingAES256(value:string) {
    let _key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef')
    let _iv = CryptoJS.enc.Hex.parse('abcdef9876543210abcdef9876543210');
    let encrypted = CryptoJS.AES.encrypt(
      value, _key, {
        iv: _iv
      });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }
  login(username: string, password: string) {
    password = this.encryptUsingAES256(password);
   
    const body = {
    username: username,
    password: password
    } 
   
    
  return this.http.post(this.baseUrl + '/users/login' , body).pipe(
    map((user: any) => {      
      if(user) {
        this.router.navigate(["/home"]);
        // localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
      }
      return user;
    })
  );
  
  }
logout() {
localStorage.removeItem('currentUser');

}

register(form: any) {

return this.http.post(this.baseUrl + '/users/register', form).subscribe(res => {
  return res;
})
}

  public currentUserValue(): User {
    return this.currentUserSubject.value;
  }
}
