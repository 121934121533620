<div class="graph_pg-wrapper">
  <div class="graph_pg-inner">
    <div class="dates_wrapper-outer">
      <div>
        <h2>{{heading}}</h2>
      </div>
      <div class="dates_wrapper">
        <div class="dates_wrap-inner">
          <button class="filterBtn" mat-button color="primary" (click)="advanceFilter()">Advanced
            Filters</button>
        </div>
        <div class="dates_wrap-inner">
          <button class="filterBtn" mat-button color="primary" (click)="resetFilters()">Reset Filters</button>
        </div>
      </div>
    </div>
    <div class="graph_wg-wrapper">
      <div class="graph_wg-inner">
        <canvas baseChart width="300" height="400" [datasets]="barChartData" [labels]="barChartLabels"
          [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
        </canvas>
      </div>
    </div>
  </div>

  <!-- Advance filters popup -->
  <div *ngIf="IsFiltering" class="overlay org_pop">
    <div class="popup">
      <a class="close" (click)="onClickCancel()">&times;</a>

      <!-- <form [formGroup]="advanceFilterForm"> -->
      <div class="form orf_frm">
        <div class="row">
          <div class="col-md-12">
            <h1 class="heading">Advanced Filters</h1>
          </div>

          <div class="col-md-12">
            <div class="form_devider_line"></div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>From Date</mat-label>
                  <input matInput [matDatepicker]="frompicker" [max]="todayDate" [(ngModel)]="fromDate" readonly="readonly">
                  <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                  <mat-datepicker #frompicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="endDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>To Date</mat-label>
                  <input matInput [matDatepicker]="topicker" [max]="todayDate" [(ngModel)]="toDate" readonly="readonly">
                  <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                  <mat-datepicker #topicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Organisations</mat-label>
                  <mat-select multiple [(ngModel)]="selectOrg">
                    <mat-option (click)="selectOrgs('all')" [value]="'all'">All</mat-option>
                    <mat-option *ngFor="let item of organisationList" (click)="selectOrgs(item.name)"
                      [value]="item.name"> {{
                      item.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Users</mat-label>
                  <mat-select [(ngModel)]="selectUsers" multiple>
                    <mat-option (click)="changeUsers('all')" [value]="'all'">All</mat-option>
                    <mat-option *ngFor="let item of usersList" (click)="changeUsers(item.email)" [value]="item.email">
                      {{
                      item.firstName}} {{
                      item.lastName}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <!-- Flter plans select boxes -->
        <div class="row">
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Insurer</mat-label>
                  <mat-select [(ngModel)]="selectInsurer" multiple>
                    <mat-option (click)="changeInsurer('all')" [value]="'all'" *ngIf="compdata">All</mat-option>
                    <mat-option *ngFor="let item of compdata" (click)="changeInsurer(item._id)" [value]="item._id"> {{
                      item.updatedName ? item.updatedName : item.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Residency</mat-label>
                  <mat-select [(ngModel)]="selectResidency" (selectionChange)="changeResidency(selectResidency)">
                    <mat-option *ngFor="let item of countryList" [value]="item"> {{ item}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Plans</mat-label>
                  <input type="text" matInput placeholder="Select Plans" aria-label="Select Plans" matInput
                    [matAutocomplete]="auto" [formControl]="selectedPlan" maxlength="0" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option [ngClass]="'list-show'" *ngIf="planData">
                      <mat-checkbox [checked]="plansParentCheckbox" (change)="selectParentCheckbox($event, 'plan')"
                        (click)="$event.stopPropagation()">
                        {{"All"}}
                      </mat-checkbox>
                    </mat-option>
                    <mat-optgroup *ngFor="let group of planData">
                      <div>
                        <mat-checkbox [checked]="group.checked" (change)="togglePlansParent($event, group)"
                          (click)="$event.stopPropagation()">
                          {{group.name}}
                        </mat-checkbox>
                      </div>
                      <mat-option *ngFor="let name of group.names" [value]="name.data" [ngClass]="'list-show'">
                        <mat-checkbox [checked]="name.checked" (change)="changePlan($event, name.data)"
                          (click)="$event.stopPropagation()">
                          {{name.data}}
                        </mat-checkbox>
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Networks</mat-label>
                  <input type="text" matInput placeholder="Select Networks" aria-label="Select Networks" matInput
                    [matAutocomplete]="auto1" [formControl]="selectedNetwork" maxlength="0" />
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option [value]="'all'" [ngClass]="'list-show'" *ngIf="networkData">
                      <mat-checkbox [checked]="networkParentCheckbox" (change)="selectParentCheckbox($event, 'network')"
                        (click)="$event.stopPropagation()">
                        {{"All"}}
                      </mat-checkbox>
                    </mat-option>
                    <mat-optgroup *ngFor="let network of networkData">
                      <div>

                        <mat-checkbox [checked]="network.checked" (change)="toggleNetworkParent($event, network)"
                          (click)="$event.stopPropagation()">
                          {{network.name}}
                        </mat-checkbox>
                      </div>
                      <mat-option *ngFor="let name of network.names" [value]="name.data" [ngClass]="'list-show'">
                        <mat-checkbox [checked]="name.checked" (change)="changeNetwork($event, name.data)"
                          (click)="$event.stopPropagation()">
                          {{name.data}}
                        </mat-checkbox>
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Coverage</mat-label>
                  <input type="text" matInput placeholder="Select Networks" aria-label="Select Networks" matInput
                    [matAutocomplete]="auto2" [formControl]="selectedCoverage" maxlength="0" />
                  <mat-autocomplete #auto2="matAutocomplete">
                    <mat-option [value]="'all'" [ngClass]="'list-show'" *ngIf="coverageList">
                      <mat-checkbox [checked]="coverageParentCheckbox"
                        (change)="selectParentCheckbox($event, 'coverage')" (click)="$event.stopPropagation()">
                        {{"All"}}
                      </mat-checkbox>
                    </mat-option>
                    <mat-optgroup *ngFor="let coverage of coverageList">
                      <div>

                        <mat-checkbox [checked]="coverage.checked" (change)="toggleInsurerParent($event, coverage)"
                          (click)="$event.stopPropagation()">
                          {{coverage.name}}
                        </mat-checkbox>
                      </div>
                      <mat-option *ngFor="let name of coverage.names" [value]="name.data" [ngClass]="'list-show'">
                        <mat-checkbox [checked]="name.checked" (change)="changeArea($event, name.data)"
                          (click)="$event.stopPropagation()">
                          {{name.data}}
                        </mat-checkbox>
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dates_wrap-inner">
              <div id="startDate">
                <mat-form-field appearance="fill" class="filter-select-box">
                  <mat-label>Select Copay</mat-label>
                  <input type="text" matInput placeholder="Select Copay" aria-label="Select Copay" matInput
                    [matAutocomplete]="auto3" [formControl]="selectedCopay" maxlength="0" />
                  <mat-autocomplete #auto3="matAutocomplete">
                    <mat-option [value]="'all'" [ngClass]="'list-show'" *ngIf="copayList">
                      <mat-checkbox [checked]="copayParentCheckbox" (change)="selectParentCheckbox($event, 'copay')"
                        (click)="$event.stopPropagation()">
                        {{"All"}}
                      </mat-checkbox>
                    </mat-option>
                    <mat-optgroup *ngFor="let copay of copayList">
                      <div>
                        <mat-checkbox [checked]="copay.checked" (change)="toggleCopayParent($event, copay)"
                          (click)="$event.stopPropagation()">
                          {{copay.name}}
                        </mat-checkbox>
                      </div>
                      <mat-option *ngFor="let name of copay.names" [value]="name.data" [ngClass]="'list-show'">
                        <mat-checkbox [checked]="name.checked" (change)="changeCopay($event, name.data)"
                          (click)="$event.stopPropagation()">
                          {{name.data}}
                        </mat-checkbox>
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button (click)="onClickCancel()" class="btn btn_Cancel" mat-raised-button color="secondary">Cancel</button>
          <button class="btn btn-primary" mat-raised-button color="primary" (click)="filterInsurerSubmit()">
            Filter
          </button>

        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>
</div>