import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import {
  ProgressSpinnerMode,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import {
  TrendsService,
  trendsFormat,
  trendsInputFormat,
} from './trends.service';
import { CompanyService } from '../Modules/company/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss'],
})
export class TrendsComponent implements OnInit {
  constructor(
    private trendsApi: TrendsService,
    private companyApi: CompanyService,
    private toaster: ToastrService
  ) {}
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  public barChartHeight: Number = 100;
  public barChartWidth: Number = 300;
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'line';
  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [];
  public timeline: String[] = [
    '1 day',
    '1 week',
    '1 month',
    '1 year',
    '2 years',
    'custom',
  ];
  public selectedTimeline: String = this.timeline[1];
  public selectedInsurers: String[] = [];
  public insurers: any = [];
  public selectedOrganisations: String[] = [];
  public organisations: any = [];
  public fromDate = '';
  public toDate = '';
  public todayDate: Date = new Date();
  public type = 'insurers';
  public color = 'primary';
  public mode: ProgressSpinnerMode = 'indeterminate';
  public loading: boolean = false;
  public disableFilter: boolean = false;

  changeInsurer(name: String) {
    this.disableFilter = false;
    if (
      (name == 'all' && this.selectedInsurers.length != this.insurers.length) ||
      (name != 'all' &&
        this.selectedInsurers.length == this.insurers.length &&
        !this.selectedInsurers.includes('all'))
    ) {
      this.selectedInsurers = ['all', ...this.insurers];
    } else if (
      name == 'all' &&
      this.selectedInsurers.length == this.insurers.length
    ) {
      this.selectedInsurers = [];
    } else {
      this.selectedInsurers = this.selectedInsurers.filter((v) => v != 'all');
    }
  }
  changeOrg(name: String) {
    this.disableFilter = false;
    if (
      (name == 'all' &&
        this.selectedOrganisations.length != this.organisations.length) ||
      (name != 'all' &&
        this.selectedOrganisations.length == this.organisations.length &&
        !this.selectedOrganisations.includes('all'))
    ) {
      this.selectedOrganisations = ['all', ...this.organisations];
    } else if (
      name == 'all' &&
      this.selectedOrganisations.length == this.organisations.length
    ) {
      this.selectedOrganisations = [];
    } else {
      this.selectedOrganisations = this.selectedOrganisations.filter(
        (v) => v != 'all'
      );
    }
  }

  setChartData(timeline: String) {
    let { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    let input: trendsInputFormat = {
      timeline,
      selectedOptions:
        this.type == 'insurers'
          ? this.selectedInsurers
          : this.selectedOrganisations,
      type: this.type,
      timeZone,
    };
    if (timeline == 'custom') {
      if (!(this.fromDate && this.toDate)) {
        this.toaster.error('Please select date range!', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      } else if (this.fromDate > this.toDate) {
        this.toaster.error("From Date can't greater than To Date", 'Error', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      } else {
        input.dates = [this.fromDate, this.toDate];
      }
    }
    this.loading = true;
    this.trendsApi.getCensusData(input).then((res: trendsFormat) => {
      this.barChartLabels = res.labels;
      this.barChartData = res.datasets;
      this.insurers = res.insurers || this.insurers;
      this.organisations = res.organisations;
      this.loading = false;
    });
  }

  setFilter() {
    this.setChartData(this.selectedTimeline);
    this.disableFilter = true;
  }
  enableFilter() {
    this.disableFilter = false;
  }

  ngOnInit(): void {
    if (window.innerHeight < 700) {
      this.barChartWidth = 400;
    } else {
      this.barChartWidth = 300;
    }
    window.addEventListener('resize', () => {
      if (window.innerHeight < 700) {
        this.barChartWidth = 400;
      } else {
        this.barChartWidth = 300;
      }
    });

    this.setChartData('1 week');
    this.disableFilter = true;
  }
}
