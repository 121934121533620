<br>
<br>
<br>
<br>
<p>line-chart works!</p>
<!--Line Chart Graph-->
<div>
    <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
        [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
    </canvas>
</div>

<!--Line Chart Graph-->
<br>
<br>

<!--Bar Chart Graph-->
<div>
    <canvas baseChart width="300" height="100" [datasets]="barChartData" [labels]="barChartLabels"
        [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"
        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
    </canvas>
</div>
<!--Bar Chart Graph-->
<br><br>
<!--Pie chart Graph-->
<div class="chartjs-container">
    <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
        [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
    </canvas>
</div>
<!--Pie chart Graph Ends-->