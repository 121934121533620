<div class="nav-left-sidebar sidebar-dark">
  <div class="menu-list">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="d-xl-none d-lg-none" routerLink="#">Dashboard</a>
      <!--class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" -->
      <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="navbarNav" aria-label="Toggle navigation">

        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav flex-column">
          <li class="nav-divider">
          </li>
          <li class="nav-item ">
            <a routerLink="quotations/create/11" class="btn btn-primary" style="width:100%;">Create Quotation</a>
          </li>
          <li class="nav-divider">
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="home"><i class="fa fa-fw fa-user-circle"></i>Dashboard</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="quotations/sent"><i class="fa fa-fw fa-copy"></i>Quotations</a>
          </li>
          <!-- <li class="nav-item ">
                                <a class="nav-link" routerLink="quotations/requests"><i class="fa fa-fw fa-list"></i>Requests <span class="requests_received">{{requestsCount}}</span></a>
                            </li> -->
          <!-- <li class="nav-item ">
                                <a class="nav-link" routerLink="clients"><i class="fa fa-fw fa-user"></i>Clients</a>
                            </li> -->
          <li class="nav-item ">
            <a class="nav-link" routerLink="companies"><i class="fa fa-fw fa-briefcase"></i>Companies</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="organisation"><i class="fa fa-fw fa-life-ring"></i>Organisation</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="etemplates"><i class="fa fa-fw fa-envelope"></i>Email Templates</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="users" ng-if="loggedUserInfo.type == 'Super Admin'"><i
                class="fa fa-fw fa-users"></i>Users</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="quickQuotes"><i class="fa fa-fw fa-life-ring"></i>Quick Quotes</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="account"><i class="fa fa-fw fa-cog"></i>My Account</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="support"><i class="fa fa-fw fa-life-ring"></i>Support & Feedback</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="privacy"><i class="fa fa-fw fa-life-ring"></i>Privacy</a>
          </li>

        </ul>

      </div>
    </nav>
  </div>
</div>
