import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { CustomersService } from './Components/customer-details/customers.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  color: ThemePalette = 'primary';
  value = 50;
  spinnerValue:boolean=false;
  constructor(private custServ:CustomersService ){

  }
  ngOnInit(){
    this.custServ.getSpinnerInfo().subscribe(
      obj => {
        this.spinnerValue=obj;

      });
  }
  title = 'saleslabb';

}
