import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent implements OnInit {
    ngOnInit(): void {
    }
    
}