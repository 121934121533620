import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AboutComponent } from 'src/app/about/about.component';
import { AlertsComponent } from 'src/app/Components/alerts/alerts.component';
import { AnalyticComponent } from 'src/app/analytic/analytic.component';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { CustomerDetailsComponent } from 'src/app/Components/customer-details/customer-details.component';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { ExtraComponent } from 'src/app/extra/extra.component';
import { FooterComponent } from 'src/app/Components/footer/footer.component';
import { HeaderComponent } from 'src/app/Components/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { LineChartComponent } from 'src/app/line-chart/line-chart.component';
import { LoginComponent } from 'src/app/login/login.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationComponent } from 'src/app/Components/navigation/navigation.component';
import { NgModule } from '@angular/core';
import { PrivacyComponent } from 'src/app/privacy/privacy.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QuotesComponent } from 'src/app/quotes/quotes.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { SiteComponent } from 'src/app/site/site.component';
import { CensusComponent } from 'src/app/census/census.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
@NgModule({
  declarations: [
    CustomerDetailsComponent,
    FooterComponent,
    HeaderComponent,
    NavigationComponent,
    LineChartComponent,
    AnalyticComponent,
    CensusComponent,
    QuotesComponent,
    SiteComponent,
    LoginComponent,
    AboutComponent,
    PrivacyComponent,
    DashboardComponent,
    ExtraComponent,
    AlertsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgbCollapseModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTooltipModule,
    MatButtonModule,
    NgbModule,
    QRCodeModule,
    ChartsModule,
    MatCheckboxModule,
    MatAutocompleteModule
    // NgxMatSelectSearchModule
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgbCollapseModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatButtonModule,
    MatExpansionModule,
    NgbModule,
    QRCodeModule,
    ChartsModule,
    CustomerDetailsComponent,
    FooterComponent,
    HeaderComponent,
    NavigationComponent,
    LineChartComponent,
    AnalyticComponent,
    SiteComponent,
    LoginComponent,
    QuotesComponent,
    AboutComponent,
    PrivacyComponent,
    DashboardComponent,
    ExtraComponent,
    AlertsComponent,
  ],
})
export class MyCommonModule {}
