import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { CensusService } from './census.service';
import * as moment from 'moment';
interface OrgListType {
  _id?: string;
  name?: string;
}

interface UserListType {
  _id?: string;
  firstName?: string;
  lastName?: string;
}

@Component({
  selector: 'app-census',
  templateUrl: './census.component.html',
  styleUrls: ['./census.component.scss'],
})
export class CensusComponent implements OnInit {
  constructor(private censusApi: CensusService) {}
  dataSet: [] = [];
  data: any;
  selectedOrg: string = '';
  selectedUser: string = '';
  orgList: OrgListType[] = [];
  userList: UserListType[] = [];
  fromDate: Date | string = '';
  toDate: Date | string = '';
  todayDate: Date = new Date();

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            max: 1,
          },
        },
      ],
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [{ data: [], fill: false }];

  ngOnInit(): void {
    this.censusApi.getCensusData().then((res: any) => {
      this.data = res.data;
      this.setData(res.data);
      this.orgList = res.data.reduce((acc: [], v: any) => {
        if (!acc.find((o: any) => o._id == v.org._id)) return [...acc, v.org];
        return acc;
      }, []);
      this.userList = this.data.reduce((acc: [], v: any) => {
        if (!acc.find((u: any) => u._id == v.userId._id))
          return [...acc, v.userId];
        return acc;
      }, []);
    });
  }

  setData(data: []): void {
    this.barChartLabels = data.reduce((acc: string[], v: any) => {
      if (acc.length == 0) return [v.census];
      else if (!acc.includes(v.census)) return [...acc, v.census];
      return acc;
    }, []);

    let n: number = 0;
    this.barChartData[0].data = this.barChartLabels.map((v) => {
      let count = data.reduce((acc: number, n: any) => {
        if (v == n.census) return acc + 1;
        return acc;
      }, 0);
      if (n < count) n = count;
      return count;
    });
    n = n % 50 < 50 ? (Math.floor(n / 50) + 1) * 50 : 50;
    this.barChartOptions = {
      responsive: true,
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
              max: n,
            },
          },
        ],
      },
    };
  }

  setOrg() {
    this.selectedUser = '';
    this.userList = this.data.reduce((acc: [], v: any) => {
      if (
        (this.selectedOrg == v.org._id || this.selectedOrg == '') &&
        !acc.find((u: any) => u._id == v.userId._id)
      )
        return [...acc, v.userId];
      return acc;
    }, []);
  }
  setFilter() {
    let fromDate =
      typeof this.fromDate == 'object' &&
      moment(this.fromDate.toLocaleDateString(), 'DD/MM/YYYY').format(
        'YYYY/MM/DD'
      );
    let toDate =
      typeof this.toDate == 'object' &&
      moment(this.toDate.toLocaleDateString(), 'DD/MM/YYYY').format(
        'YYYY/MM/DD'
      );

    let filteredData = this.data.filter((v: any) => {
      let createDate = moment(
        new Date(v.updatedAt).toLocaleDateString(),
        'DD/MM/YYYY'
      ).format('YYYY/MM/DD');
      if (!v.updatedAt || (this.fromDate == '' && this.toDate == ''))
        return true;
      let check = fromDate <= createDate && toDate >= createDate;
      // console.log('toDate --> ', {
      //   createDate,
      //   fromDate,
      //   toDate,
      //   checkFrom: fromDate <= createDate,
      //   checkto: toDate >= createDate,
      // });
      return check;
    });
    if (this.selectedOrg != '')
      filteredData = filteredData.filter(
        (v: any) => v.org._id == this.selectedOrg
      );

    if (this.selectedUser != '')
      filteredData = filteredData.filter(
        (v: any) => v.userId._id == this.selectedUser
      );

    this.setData(filteredData);
    // console.log('this --> ', filteredData);
    // this.getDate(this.toDate);
  }
}
