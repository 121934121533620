import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoginDto } from 'src/app/login/login.component';
import { environment } from 'src/environments/environment';
import { Users } from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  users!: LoginDto
 public base_url = environment.BaseURL
 noAuthHeader = { headers: new HttpHeaders({ NoAuth: 'True' }) };

  constructor(private http: HttpClient) { 

  
  }
  
public datas = new BehaviorSubject<LoginDto>(this.users)

getData(data: any) {
  this.datas.next(data);
 
}
  

}
