import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../Components/customer-details/customers.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private custServ:CustomersService ) { }

  ngOnInit(): void {
    this.custServ.setLogoValue(false);
  }

}
