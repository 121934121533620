
<router-outlet>
    <div class="overlay" *ngIf="spinnerValue"></div>
    <mat-progress-spinner *ngIf="spinnerValue"
    class="example-margin"
    mode="indeterminate">
    </mat-progress-spinner>
 <app-navigation>
     
 </app-navigation>

<!-- <app-header></app-header> -->

    <alerts></alerts>



<app-footer>
    
</app-footer>


</router-outlet>
