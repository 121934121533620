import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { environment } from 'src/environments/environment';

const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root',
})
export class TrendsService {
  public Base_Url = environment.BaseURL;
  constructor(private httpClient: HttpClient) {}

  getCensusData(data: trendsInputFormat) {
    return this.httpClient
      .post<trendsFormat>(this.Base_Url + '/companies/InsurerTrends', data, {
        headers: headers,
      })
      .toPromise();
  }
}

export interface trendsFormat {
  labels: string[];
  datasets: ChartDataSets[];
  insurers: string[];
  organisations: string[];
}

export interface trendsInputFormat {
  timeline: String;
  selectedOptions?: String[];
  dates?: String[];
  type: String;
  timeZone?: String;
}
