<div class="list_details">
    <div class="card">
        <div class="card-header">
        <img src="assets/images/saleslab_final_logo.png" class="card-img-top" alt="...">
      </div>
        <div class="card-body">
            <h1 class="list_heading">User Verification 
                <i class="fa fa-key" aria-hidden="true"></i>
            </h1>
           <h3>Please Enter 6 Digit verification Code</h3>
            <ng-otp-input class="otp_input" (onInputChange)="onOtpChange($event)"  [config]="{length:6}"></ng-otp-input>
         
            <p *ngIf="timer" class="timer">Your Otp Will Expire in   {{time | formatTime}}</p>
            <!-- <cd-timer format="ms" #basicTimer [startTime]="0" [endTime]="120"></cd-timer> --> 
          
            <button *ngIf="!timer"  mat-raised-button color="secondary"  (click)="resendOtp()">Resend OTP</button>
           <button class="verify_code" mat-raised-button color="primary" (click)="verifyOtp()">VERIFY OTP</button>
        
        </div>
      </div>   
</div>