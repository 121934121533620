import { Component, ElementRef, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription, timer } from 'rxjs';
import { CustomersService } from '../Components/customer-details/customers.service';
import { LoginDto } from '../login/login.component';
import { AccountService } from '../Modules/account/account.service';
import { AuthenticationService } from '../services/authentication.service';


@Component({
  selector: 'app-otp-signin',
  templateUrl: './otp-signin.component.html',
  styleUrls: ['./otp-signin.component.scss']
})
export class OtpSigninComponent implements OnInit {

  username: string = "";
  otp!: number;
  interval: any;
  pass: string = "";


  time = 120;
  timer = true;
  constructor(private auth: AuthenticationService, private accserve: AccountService, private custServ: CustomersService, private router: Router) {

  }

  ngOnInit(): void {
    this.accserve.datas.asObservable().subscribe(res => {
      this.username = res.email;
      this.pass = res.password;
    })


    this.startTime()

  }

  onOtpChange(data: any) {
    this.otp = data;
  }

  startTime() {
    setInterval(() => {
      if (this.time > 0) {
        this.time--;
      } else if (this.time == 0) {
        this.timer = false;
        // this.time = 10;
      }

    }, 1000)
  }

  verifyOtp() {
    this.auth.verifyOtp(this.username, this.otp).subscribe(
      (res: any) => {
        this.auth.setToken(res._id);
        this.auth.setUser(res.data);
        this.router.navigate(['/dashboard']);
        this.custServ.setLogoValue(true);
      },
      (err) => {

      }
    )
  }
  resendOtp() {
    let loginDto: LoginDto = { email: this.username, password: this.pass };
    this.auth.login(loginDto).subscribe((data: any) => {
      this.timer = true;
      if (this.time <= 0) {
        this.time = 120;
      }
    });

  }

}

@Pipe({
  name: "formatTime"
})

export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

}