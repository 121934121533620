import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { ResetpasswordService } from '../resetpassword/resetpassword.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 public loginType = new Subject();

 public isLogged = new  BehaviorSubject({});
public resetData = new Subject();

  constructor(private http: HttpClient,
    private _router: Router,private cookie:CookieService,
    private toaster: ToastrService, private resetserve: ResetpasswordService
  ) {


  }
  private headers = new HttpHeaders().append('content-type', 'application/json');

  noAuthHeader = { headers: new HttpHeaders({ NoAuth: 'True' }) };
  public base_url = environment.BaseURL

  login(authCredential: any): Observable<any> {
    const body = {
      username: authCredential.email,
      password: authCredential.password
    }
    const headers = new HttpHeaders({'content-type': 'application/json'})
    return this.http.post(this.base_url + '/users/login', body, {'headers': headers})
    .pipe(
      map((response: any) => {
      if(response.status == 200) {
        this.isLogged.next(response);
        this.toaster.success('Please Provide OTP')         
        return response;
      }   
      }),
      catchError((error) => {
        return error
      })
 );
  }
  // register(user:any){

  // return this.http.post(this._registerUri,user);
  // }


  forgotPasswordMail(email: any) {
var mail ={'email':email}
  
  return this.http.post(this.base_url + '/users/forgotPassword' , mail, { 'headers': this.headers}).pipe(
    map((response) => {
  this.toaster.success('Mail Send SuccessFully');
     this.resetserve.params.next(response);
      return response
    }), catchError((error) => {
      this.toaster.error('cannot Send Reset Password mail');
      return error;
    }) 
  )
  }

  logout() {
    localStorage.clear()


    this._router.navigate(['/login']);
  }

verifyOtp(user: any, otp: any) {
    const body = {
      username:user,
      otp: otp
    }
    const headers = new HttpHeaders({'content-type': 'application/json'});
    return this.http.post(this.base_url + '/users/otp/verify', body, {'headers': headers}).pipe(
      map((response:any) => {

        if(response) {
          this.toaster.success('User Login SuccesFully')
          this.loginType.next(response.type);
          return response;
        } else {
          this.toaster.error()
        }
       
      })
    )
}


  setToken(token: string) {
    localStorage.setItem("TOKEN", JSON.stringify(token));

  }
  setUser(user: any) {
    localStorage.setItem("USER", JSON.stringify(user));
    this.cookie.set('USER',  JSON.stringify(user))


  }
  getToken() {
    let token = localStorage.getItem("TOKEN");
    if (token == null) {
      return null;
    }
    else {
      return JSON.parse(token);
    }
  }


  getUser() {
    let usr = localStorage.getItem("USER");
    if (usr == null) {
      return null;
    }
    else {
      return JSON.parse(usr);
    }


  }
  loggedIn() {
    return !!localStorage.getItem("USER");
  }
}
function err(err: any, any: any): (err: any, caught: Observable<any>) => import("rxjs").ObservableInput<any> {
  throw new Error('Function not implemented.');
}

