<div class="page-wrapper">
    <!-- <header class="siteHeader">
        <div class="container">
            <nav class="navbar navbar-expand-xl px-0 justify-content-center justify-content-sm-between">
                <div class="header-logo">
                  <a class="navbar-brand" href="/" title="SalesLabb">
                    <img src="assets/images/saleslab_final_logo.png" alt="SalesLabb" width="auto" class="header-logo">
                  </a>
                </div>
                <ul class="nav justify-content-center justify-content-sm-end px-0">
                    <li>
                        <a class="nav-link text-secondary" href="/login" title="Sign in"><small>Sign in</small></a>
                    </li>
                    <li>
                        <a class="nav-link text-secondary" href="/about" title="About Us"><small>About Us</small></a>
                    </li>
                </ul>
            </nav>
        </div>
    </header> -->
    

    <section class="homeBanner secPadd pb-0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="caption text-center">
                        <h1 class="banner-title mb-5">Insure your clients faster than ever</h1>
                        <p class="description mb-5">SalesLabb is a sales tool designed for advisors to provide faster and
                            more accurate proposals to customers. If you are an insurance company or broker and are looking
                            for a boom in clients buying insurance, then you are in the right place.</p>
                        <div class="btn-group-wrap mb-5" role="group">
                            <a href="#" class="btn btn-primary banner_btn" title="1 Month free trial" data-toggle="modal"
                                data-target="#create" (click)="clickToEdit('1 month free trial')">1 month free trial</a>
                            <a href="#" class="btn btn-outline-primary banner_btn" title="Request demo" data-toggle="modal"
                                data-target="#create" (click)="clickToEdit('Request demo')">Request demo</a>
                        </div>
                        <div class="image-wrap pt-5">
                            <img src="assets/images/bannerinfographic.png" alt="Insure your clients faster than ever"
                                width="240" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section class="partners_wrapper secPadd">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sec-title text-center mb-5 max-w-767">
                        <h2 class="title">Partners onboarded</h2>
                        <p class="description">SalesLabb has partnered with some of the world's best insurance providers and
                            is constantly adding more providers to its diverse portfolio.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="gallerOfImages">
                        <ul class="itemsRow4">
                            <li>
                                <a target="_black" title="inteGra GLOBAL">
                                    <img src="assets/images/2.png" alt="inteGra GLOBAL" class="img-fluid" />
                                </a>
                            </li>
                            <li>
                                <a target="_black" title="Cigna">
                                    <img src="assets/images/1.png" alt="Cigna" class="img-fluid" />
                                </a>
                            </li>
                            <li>
                                <a target="_black" title="Daman">
                                    <img src="assets/images/3.png" alt="Daman" class="img-fluid" />
                                </a>
                            </li>
                            <li>
                                <a target="_black" title="Bupa">
                                    <img src="assets/images/4.png" alt="Bupa" class="img-fluid" />
                                </a>
                            </li>
                            <li>
                                <a target="_black" title="aetna">
                                    <img src="assets/images/5.png" alt="aetna" class="img-fluid" />
                                </a>
                            </li>
                            <li>
                                <a target="_black" title="HanseMerkur">
                                    <img src="assets/images/6.png" alt="HanseMerkur" class="img-fluid" />
                                </a>
                            </li>
                            <li>
                                <a target="_black" title="watania">
                                    <img src="assets/images/7.png" alt="watania" class="img-fluid" />
                                </a>
                            </li>
                            <li>
                                <a target="_black" title="Allianz">
                                    <img src="assets/images/8.png" alt="Allianz" class="img-fluid" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    

    <section class="buyInsurance pt-4" style="background-image: url(assets/images/site-page-back.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="formWrapBox">
                        <div class="formContent">
                            <div class="sec-title text-md-left mb-5">
                                <h2 class="title">Here to buy insurance ?</h2>
                                <p class="description">
                                    Send us your details and a broker will contact you shortly
                                </p>
                            </div>
                            <div class="insurance">
                                <form class="form" name="insuranceForm" [formGroup]="insuranceForm">
                                    <div class="form-group">
                                        <mat-form-field class="form-control">
                                            <input matInput formControlName="fName" placeholder="First Name">
                                        </mat-form-field>
                                        <div *ngIf="insuranceForm.controls['fName'].invalid && (insuranceForm.controls['fName'].dirty || insuranceForm.controls['fName'].touched)"
                                            class="alert alert-danger">
                                            First Name is required.
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="form-control">
                                            <input matInput formControlName="lName" placeholder="Last Name">
                                        </mat-form-field>
                                        <div *ngIf="insuranceForm.controls['lName'].invalid && (insuranceForm.controls['lName'].dirty || insuranceForm.controls['lName'].touched)"
                                            class="alert alert-danger">
                                            Last Name is required.
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="form-control">
                                            <input matInput formControlName="mobile"
                                                placeholder="Mobile Number or Email">
                                        </mat-form-field>
                                        <div *ngIf="insuranceForm.controls['mobile'].invalid && (insuranceForm.controls['mobile'].dirty || insuranceForm.controls['mobile'].touched)"
                                            class="alert alert-danger">
                                            Mobile Number or Email is required.
                                        </div>

                                    </div>

                                    <div class="btn-wrap">
                                        <button type="submit" class="btn btn-primary w-100 d-block"
                                            (click)="submitInsurance()">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<section class="ourClientSay secPadd">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="sec-title text-center mb-5 max-w-767">
                    <h2 class="title">What our clients say</h2>
                    <p class="description">Our clients send us a bunch of smiles by using our services and we love
                        them</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="testimonialsWrapper">
                    <div>
                        <div class="testimonialItem">
                            <div class="container-fluid">
                                <div class="row align-items-center">
                                    <div class="col-12 col-md-4">
                                        <div class="clientImage text-center text-md-right">
                                            <img src="assets/images/profileImgUser.png" alt="Client Image"
                                                class="img-fluid" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8">
                                        <div class="clientInfo">
                                            <div class="clientMessage">
                                                <p class="description mb-0">
                                                    “SalesLabb is an amazing tool for Insurance consultants to helps
                                                    get quotes in no time. I have been using the tool for the past 6
                                                    months, I find that it is extremely useful !! I recommend that
                                                    every Insurance sales person should us this tool.” - Sai
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                        <div class="testimonialItem">
                            <div class="container-fluid">
                                <div class="row align-items-center flex-sm-column-reverse flex-md-row mob_rev-row">
                                    <div class="col-12 col-md-8">
                                        <div class="clientInfo">
                                            <div class="clientMessage">
                                                <p class="description mb-0">
                                                    "The tool is user friendly and helps save a considerable amount
                                                    of time. The ‘Manual Quote’ option is one of the most useful
                                                    features as it helps in selecting the required plans quickly
                                                    without having to browse through a long list of options." -
                                                    Nishma</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="clientImage text-center text-md-right">
                                            <img src="assets/images/profileImgUserGirl.png" alt="Client Image"
                                                class="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    
<section class="getInTouch secPadd">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="sec-title text-center mb-5 max-w-767">
                    <h2 class="title">Get in touch</h2>
                    <p class="description">Have an inquiry or any feedback for us? Fill out the form below and our
                        team will get in touch with you at the earliest.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="contact">
                    <div class="formFieldsWrap max-w-375">
                        <form class="form" name="inquiryForm" [formGroup]="inquiryForm">
                            <div class="form-group">
                                <mat-form-field class="form-control">
                                    <input matInput formControlName="inquiryFirstName" placeholder="First Name">
                                </mat-form-field>
                                <div *ngIf="inquiryForm.controls['inquiryFirstName'].invalid && (inquiryForm.controls['inquiryFirstName'].dirty || inquiryForm.controls['inquiryFirstName'].touched)"
                                    class="alert alert-danger">
                                    First Name is required.
                                </div>

                            </div>
                            <div class="form-group">
                                <mat-form-field class="form-control">
                                    <input matInput formControlName="inquiryEmail" placeholder="Email">
                                </mat-form-field>
                                <div *ngIf="inquiryForm.controls['inquiryEmail'].invalid && (inquiryForm.controls['inquiryEmail'].dirty || inquiryForm.controls['inquiryEmail'].touched)"
                                    class="alert alert-danger">
                                    Email is required.
                                </div>

                            </div>
                            <div class="form-group">
                                <mat-form-field class="form-control">
                                    <input matInput formControlName="inquiryMobNo" placeholder="Mobile Number">
                                </mat-form-field>
                                <div *ngIf="inquiryForm.controls['inquiryMobNo'].invalid && (inquiryForm.controls['inquiryMobNo'].dirty || inquiryForm.controls['inquiryMobNo'].touched)"
                                    class="alert alert-danger">
                                    Mobile Number is required.
                                </div>

                            </div>
                            <div class="form-group mb-5">
                                <mat-form-field class="form-control">
                                    <textarea matInput formControlName="inquiryMsg" placeholder="Message"
                                        rows="4"></textarea>
                                </mat-form-field>
                                <div *ngIf="inquiryForm.controls['inquiryMsg'].invalid && (inquiryForm.controls['inquiryMsg'].dirty || inquiryForm.controls['inquiryMsg'].touched)"
                                    class="alert alert-danger">
                                    Message is required.
                                </div>


                            </div>
                            <div class="btn-wrap">
                                <button type="submit" class="btn btn-primary w-100 d-block"
                                    (click)="sendDetail()">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    
<footer class="siteFooter">
    <div class="footerWidgets text-center pt-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footerWidgetsTop py-5">
                        <div class="footer-logo mb-3">
                            <a class="navbar-brand" href="/" title="SalesLabb">
                                <img src="assets/images/saleslab_final_logo.png" alt="SalesLabb" width="245px">
                            </a>
                        </div>
                        <address class="mb-0">
                            Vita Virtues LLC
                            <br>
                            600 N Broad Street<br>
                            Suite 5 #3504<br>
                            Middletown, DE 19709
                            <br>
                        </address>
                    </div>
                    <div class="quicklinks border-top py-4">
                        <ul class="navbar nav justify-content-center p-0">
                            <li class="nav-item">
                                <a class="nav-link" href="/privacy">Terms & Conditions</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyrightFooter py-4">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-8 order-2 order-lg-1">
                    <p class="mb-0 text-lg-left">Copyright © 2021 Vita Virtues LLC. All rights reserved
                    </p>
                </div>

            </div>
        </div>
    </div>
</footer>

</div>
<ng-template #create let-modal>
    <div class="modal fade" id="create" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">1 month free trial</h5>
                    <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label for="inputText3" class="col-form-label">First Name
                                    <span class="tool" data-tip="First Name"><i
                                            class="fas fa-question-circle lt-grey"></i></span></label>
                                <input type="text" class="form-control" id="FirstName" aria-describedby="First Name"
                                    placeholder="First Name" ng-model="member.firstName">
                            </div>

                        </div>
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label for="inputText3" class="col-form-label">Last Name
                                    <span class="tool" data-tip="Last name"><i
                                            class="fas fa-question-circle lt-grey"></i></span></label>
                                <input type="text" class="form-control" id="LastName" aria-describedby="Last Name"
                                    placeholder="Last Name" ng-model="member.lastName">
                            </div>

                        </div>
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label for="inputText3" class="col-form-label">Phone No.
                                    <span class="tool" data-tip="Phone No."><i
                                            class="fas fa-question-circle lt-grey"></i></span></label>
                                <input type="text" class="form-control" id="Phone" aria-describedby="Phone No."
                                    placeholder="Phone Number" ng-model="member.phoneNumber">
                            </div>

                        </div>
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label for="inputText3" class="col-form-label">Email
                                    <span class="tool" data-tip="Email"><i
                                            class="fas fa-question-circle lt-grey"></i></span></label>
                                <input type="text" class="form-control" id="email" aria-describedby="Email"
                                    placeholder="Email" ng-model="member.email">
                            </div>

                        </div>
                        <!--<div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="inputText3" class="col-form-label">Insurance Brokers
                                    <span class="tool" data-tip="Insurance Brokers"><i class="fas fa-question-circle lt-grey"></i></span></label>
                                        <select class="form-control" id="input-select"  id="Insurance Brokers" aria-describedby="Insurance Brokers" placeholder="Insurance Brokers"  ng-model="member.brokers">
                                        <option value="" disabled selected hidden>Choose Broker</option>
                                        <option value="{{item.name}}" ng-repeat="item in brokers">{{item.name}}</option>
                                        </select>
                                </div>
                            </div>-->
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#" class="btn btn-light" data-dismiss="modal">Close</a>
                    <a href="#" class="btn btn-primary" data-dismiss="modal" ng-click="addRequest()">Submit</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>