import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Company, KeyValue, PromoDiscount, deleteDiscount, discountDetails, plans } from './company';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Organisation } from '../organisations/organisation';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  id!: string;
  orgname!: KeyValue;
  constructor(private http: HttpClient, private toaster: ToastrService) {

  }

  private companyGlobalData: Company[] = [];
  private storage = new Subject<Company>();
  stage$ = this.storage.asObservable();
  public closeBtn = new Subject();
  public ShowForm = new BehaviorSubject<string>(this.id);

  public orgNameList: KeyValue[] = [];

  public hideOrg = new Subject<boolean>();
  public userReset = new Subject();
  public orgReset = new Subject();
  public organisation = new BehaviorSubject<any>(this.orgname)
  public subscribe = this.ShowForm.asObservable();
  public Base_URl = environment.BaseURL;
  public Base_Url = environment.OldBaseURL;

  private headers = new HttpHeaders().append('content-type', 'application/json');


  getComp() {
    return this.http.get<Company>(this.Base_URl + '/companies');
  }

  getcompanydetailAlongwithPlans() {
    return this.http.get<Company>(this.Base_URl + '/companies/companyAlongWithRes');
  }

  getCompResidencies(data: Array<[]>) {
  
    return this.http.post<Company>(this.Base_URl + `/companies/companies-residency`, data,  { 'headers': this.headers });
  }

  sendCompanyForm(data: Company) {
    let params = new HttpParams();
    params.append('id', data.id);
    return this.http.post(this.Base_URl + '/companies/create', data, { 'headers': this.headers, params: params }).subscribe(res => {
      if (res) {
        this.toaster.success('Created Company SuccessFully')
        this.userReset.next(true);
        this.orgReset.next(true);
      } else {
        this.toaster.error('Company is not created')
      }
    })
  }



  toUpdateCompData(id: string): Company | undefined {
    return this.companyGlobalData.find(x => x.id == id);
  }

  uploadPlans(data: plans) {

    return this.http.post(this.Base_URl + '/plans', data, { 'headers': this.headers }).subscribe(res => {

    })
  }
  updateCompData(data: Company) {


    let body = JSON.stringify(data);

    return this.http.put(this.Base_URl + '/companies/' + data._id, body, { 'headers': this.headers }).subscribe(res => {
      if (res) {
        this.userReset.next(true);
        this.orgReset.next(true);
        this.toaster.success('Updated Company Successfully')
      } else {
        this.toaster.error('Company not get Updated')
      }
    })

  }


  updateOrglist(data: Company) {
    return this.http.post(this.Base_URl + '/companies/updateOrganisations', data).subscribe(res => {
      if (res) {
        this.userReset.next(true);
        this.orgReset.next(true);
        this.toaster.success('Updated Organisation SuccessFully')
      } else {
        this.toaster.error('Organisation is not Updated')
      }


    })
  }

  deleteComp(id: any) {

    return this.http.delete(this.Base_URl + '/companies/' + id, { 'headers': this.headers }).subscribe(res => {

      if (res == null) {
        this.userReset.next(true);
        this.orgReset.next(true);
        this.toaster.success('Company Deleted SuccessFully')
      } else {
        this.toaster.error('Company is not Deleted')
      }
    })
  }

  deactivateComp(data: Company) {

    let comp = {
      id: data._id,
      updateStatus: data.status == true ? 1 : 0
    }
    return this.http.post(this.Base_URl + '/companies/change_status', comp, { 'headers': this.headers }).subscribe(res => {
      if (res) {
        this.userReset.next(true);
        this.orgReset.next(true);
        this.toaster.success('Status Change SuccessFully')
      }
      else {
         this.toaster.error('Company Status is not Changed')
      }

    })
  }

  updatePromo(data: PromoDiscount) {
    return this.http.post(this.Base_Url + '/companies/updatePromotionalDiscount/add', data, { 'headers': this.headers }).subscribe(res => {
      if (res) {
        this.userReset.next(true);
        this.orgReset.next(true);
        this.toaster.success('Updated  Promotional Discount')
      } else {
        this.toaster.error('Promotional Discount is not Updated')
      }
    })

  }


  deletePromo(data: deleteDiscount) {
    return this.http.post(this.Base_Url + '/companies/updatePromotionalDiscount/delete', data, { 'headers': this.headers }).subscribe(res => {
      if (res) {
        this.userReset.next(true);
        this.orgReset.next(true);
        this.toaster.success('Delete promotional discount successfully');
      } else {
        this.toaster.error('Promotional Discount is not Deleted')
      }
    })
  }
}