import * as moment from 'moment';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType, Chart } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import { AnalyticService } from './analytic.service';
import { AuthenticationService } from '../services/authentication.service';
import { CustomersService } from '../Components/customer-details/customers.service';
import { from } from 'rxjs';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-analytic',
  templateUrl: './analytic.component.html',
  styleUrls: ['./analytic.component.scss'],
})
export class AnalyticComponent implements OnInit {
  orgData: any;
  dateOrg: any;
  chartsBy: string = 'organisations';
  selectOrg: any;
  heading: string = 'Organisation based No. of Quotation';
  quotesData: any;
  quotesArray: any[] = [];
  quotesLabel: any[] = [];
  quotesValue: any[] = [];
  dateQuotesArr: any[] = [];
  dateQuotesLabel: any[] = [];
  dateQuotesValue: any[] = [];
  fromDate: any;
  toDate: any;
  todayDate: Date = new Date();
  quoteCount: any = 0;

  handleClick = (e: any, bars: any) => {   
    let label = bars[0]?._model.label;
    let clickBarDetails = this.quotesArray.filter(
      (data: any) => data.org == label || data.user == label
    );
    let clickBarCount = clickBarDetails.reduce(
      (accum, item) => accum + item.quotes.total,
      0
    );
    let selectDeselectBar = clickBarDetails?.length
      ? clickBarDetails[0].status == 1
        ? true
        : false
      : null;
    selectDeselectBar
      ? (this.quoteCount -= clickBarCount)
      : (this.quoteCount += clickBarCount);
    this.quotesArray.forEach((data: any) => {
      if (data.org == label || data.user == label) {
        selectDeselectBar ? (data.status = 0) : (data.status = 1);
      }
    });
    this.chartsBy == 'users'
      ? this.changeOrg(this.selectOrg, false)
      : this.changeCharts(this.chartsBy);
  };

  //Bar Chart Configuration
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    onClick: this.handleClick,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [{ data: [], fill: false }];

  dateMoment = moment();
  // Bar Chart Configuration Ends

  constructor(
    private analyticService: AnalyticService,
    private authService: AuthenticationService,
    private custSer: CustomersService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.custSer.setLogoValue(false);
    this.custSer.setSpinnerInfo(true);
    //this.signUp()
    //this.signIn()
    this.analyticService
      .getAnalyticData()
      .then((data) => {
        this.orgData = data;
        this.setChartData();
        this.custSer.setSpinnerInfo(false);
      })
      .catch((error) => {
        console.log('Error', error);

        this.custSer.setSpinnerInfo(false);
        if (error.statusText == 'Unauthorized' && error.status == '401') {
          this.authService.logout();
        }
      });
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  changeCharts(value: string, isOrgChanged: Boolean = false) {
    if (value == 'organisations') {
      this.heading = 'Organisations based No. of Quotation';
      let arr = this.quotesArray.filter((x) => x.quotes !== undefined);
      let result = arr.reduce(function (acc, val) {
        var o = acc
          .filter(function (obj: any) {
            return obj.org === val.org;
          })
          .pop() || { org: val.org, quotes: 0, status: val.status };

        o.quotes += val.quotes.total;
        acc.push(o);
        return acc;
      }, []);
      let finalresult = result.filter(function (itm: any, i: any, a: any) {
        return i == a.indexOf(itm);
      });

      this.quotesLabel = finalresult.map((x: any) => x.org);
      this.quotesValue = finalresult.map((x: any) => x.quotes);
      let colorArr = this.chartColorByData(finalresult);
      this.barChartData[0].data = this.quotesValue;
      this.barChartData[0].backgroundColor = colorArr;
      this.barChartLabels = this.quotesLabel;
      this.resetData(finalresult);      
    } else {
      // this.selectOrg = [];
      this.heading = 'Users based No. of Quotation';
      let arr = this.quotesArray.filter((x) => x.quotes !== undefined);
      if(isOrgChanged)
      arr = this.selectOrg?.length > 1 ? arr : []
      this.quotesLabel = arr.map((x: any) => x.user);
      this.quotesValue = arr.map((x: any) => x.quotes.total);
      this.resetData(arr);
      let colorArr = this.chartColorByData(arr);
      this.barChartData[0].data = this.quotesValue;
      this.barChartData[0].backgroundColor = colorArr;
      this.barChartLabels = this.quotesLabel;
      this.selectOrg = [];

    }
  }

  chartColorByData(data: any) {
    return data.map((x: any) => {
      return x.status ? '#ffa1b5' : '#D4D5DC';
    });
  }

  changeOrg(value: any, reCalculate: any = true) {
    let selectedOrg = [...this.orgData.map((item: any) => item.name), 'all'];
    if ((value == 'all' || this.selectOrg?.length == selectedOrg.length - 1) && this.selectOrg.includes(value))
        this.selectOrg = selectedOrg;
    else if (value == 'all' && this.selectOrg.length > 1) 
        this.selectOrg = [];
    else if (value != 'all' && this.selectOrg?.includes('all'))
        this.selectOrg = this.selectOrg.filter((v: any) => v != 'all');

    if (this.selectOrg && this.selectOrg.length > 0) {
      let orgArr: any = [];
      this.heading = 'Organisation based No. of Users Quotation';
      this.quotesArray.map((x: any) => {
        this.selectOrg.map((y: any) => {
          if (y == x.org) {
            orgArr.push(x);
          }
        });
      });
      let arr = orgArr.filter((x: any) => x.quotes !== undefined);

      let colorArr = this.chartColorByData(arr);
      this.quotesLabel = arr.map((x: any) => x.user);
      this.quotesValue = arr.map((x: any) => x.quotes.total);
      reCalculate ? this.resetData(arr) : null;
      this.barChartData[0].data = this.quotesValue;
      this.barChartData[0].backgroundColor = colorArr;
      this.barChartLabels = this.quotesLabel;
    } else {
      let isOrgChanged = value?.length ? true : false
      this.resetData(this.quotesArray);
      this.changeCharts(this.chartsBy, isOrgChanged);
    }
  }
  setChartData() {
    this.quotesArray = [];
    this.orgData.map((x: any) => {
      return x.users.map((y: any) => {
        let fname = y.firstName ? y.firstName : `Unknown - ${y.email}`;
        let lname = y.lastName ? y.lastName : '';
        this.quotesArray.push({
          org: x.name,
          quotes: y.quotes[0],
          user: fname + ' ' + lname,
          status: 1,
        });
      });
    });

    this.quotesArray = this.quotesArray.filter((d) => d.quotes != undefined);
    this.quoteCount = this.quotesArray.reduce(
      (accum, item) => accum + item.quotes.total,
      0
    );
    this.changeCharts(this.chartsBy);
    this.changeOrg(this.selectOrg);
  }
  signUp() {
    let data = {
      email: 'priyadanu99@gmail.com',
      password: 'priya12345',
    };
    this.analyticService
      .signUp(data)
      .then((data) => {
        console.log('Response', data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  signIn() {
    let data = {
      email: 'priyadanu99@gmail.com',
      password: 'priya12345',
    };
    this.analyticService
      .signIn(data)
      .then((data) => {
        console.log('Response', data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  setFilter() {
    this.dateQuotesArr = [];
    let fromDate = moment(this.fromDate).format('YYYY-MM-DD');
    let toDate: any = moment(this.toDate).format('YYYY-MM-DD');
    if (this.fromDate == undefined && this.toDate == undefined) {
      if (fromDate > toDate) {
        alert('Please select the correct date range !');
      } else {
        alert('Please select the Start Date and End Date !');
      }
    } else if (this.fromDate != undefined && this.toDate == undefined) {
      alert('Please select the End Date !');
    } else if (this.fromDate == undefined && this.toDate != undefined) {
      alert('Please select the Start Date !');
    } else if (this.fromDate != undefined && this.toDate != undefined) {
      if (fromDate > toDate) {
        alert('Please select the correct date range !');
      } else {
        toDate = new Date(toDate).setUTCHours(23, 59, 59, 999)
        this.analyticService
          .getAnalyticDataFiltered(fromDate, toDate)
          .then((data) => {
            this.orgData = data;
            this.setChartData();
          })
          .catch((error) => {
            console.log('Error', error);
            this.custSer.setSpinnerInfo(false);
            if (error.statusText == 'Unauthorized' && error.status == '401') {
              this.authService.logout();
            }
          });
      }
    }
  }

  resetData(arr: any) {  
    this.quoteCount = arr.reduce(function (acc: any, i: any) {
      let count = i.quotes.total ? i.quotes.total : i.quotes;
      if (!i.status) return acc;
      return acc + count;
    }, 0);
  }

  resetFilters () {
    window.location.reload();
  }
}
