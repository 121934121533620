import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetpasswordService {

  public params = new Subject();

  constructor(private http: HttpClient) { }
  public base_url = environment.BaseURL

  resetPassword(cred: any) {
    console.log(cred,"djggsakLG")
    const data = {'token':cred.token, 'newpass': cred.password, 'confirmpass': cred.confirmpassword }
    return this.http.post(this.base_url + '/users/resetPassword', data).pipe(
      map((response) => {
       return response;
      }),
      catchError((error) => {
        return error;
      })
    )
  }

}
