import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { AnalyticComponent } from './analytic/analytic.component';
import { AuthGuard } from './guards/auth.guard';
import { CustomerDetailsComponent } from './Components/customer-details/customer-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExtraComponent } from './extra/extra.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './Components/notfound/notfound.component';
import { OtpSigninComponent } from './otp-signin/otp-signin.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { QuotesComponent } from './quotes/quotes.component';
import { SiteComponent } from './site/site.component';
import { SupportComponent } from './support/support.component';
import { UsersGuard } from './guards/users.guard';
import { CensusComponent } from './census/census.component';
import { TrendsComponent } from './trends/trends.component';

const routes: Routes = [
  {
    path: 'analytic/f321413c',
    component: AnalyticComponent,
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin', 'Admin'],
    },
  },
  {
    path: 'census/f321413c',
    component: CensusComponent,
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin', 'Admin'],
    },
  },
  {
    path: 'trends/f321413c',
    component: TrendsComponent,
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin', 'Admin'],
    },
  },
  {
    path: 'quotes/f321413c',
    component: QuotesComponent,
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin', 'Admin'],
    },
  },
  {
    path: '',
    component: SiteComponent,
  },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: '4',
    component: ExtraComponent,
  },
  {
    path: '1',
    component: CustomerDetailsComponent,
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./Modules/customer-info/customer-info.module').then(
        (m) => m.CustomerInfoModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'f321413c/organisations',
    loadChildren: () =>
      import('./Modules/organisations/organisations.module').then(
        (m) => m.OrganisationsModule
      ),
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin', 'Admin'],
    },
  },

  {
    path: 'organisations',
    loadChildren: () =>
      import('./Modules/organisations/organisations.module').then(
        (m) => m.OrganisationsModule
      ),
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin', 'Admin'],
    },
  },

  {
    path: 'f321413c/viewquotations',
    loadChildren: () =>
      import('./Modules/quotation/quotation.module').then(
        (m) => m.QuotationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'f321413c/user',
    loadChildren: () =>
      import('./Modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin'],
    },
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./Modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard, UsersGuard],
    data: {
      expectedRoles: ['Super Admin'],
    },
  },
  {
    path: 'f321413c/company',
    loadChildren: () =>
      import('./Modules/company/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./Modules/company/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'viewqoutes',
    loadChildren: () =>
      import('./Modules/quotation/quotation.module').then(
        (m) => m.QuotationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./Modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./Modules/account/account-routing.module').then(
        (m) => m.AccountRoutingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgotpassword',
    component: LoginComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chart',
    component: LineChartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'support',
    component: SupportComponent,
  },

  {
    path: 'verification',
    component: OtpSigninComponent,
  },
  {
    path: 'resetPassword/:id',
    loadChildren: () =>
      import('../app/resetpassword/resetpassword.module').then(
        (m) => m.ResetpasswordModule
      ),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
