<div class="graph_pg-wrapper">
  <div class="dates_wrapper-outer">
    <h1>Census Collection Data</h1>
    <div class="dates_wrap-inner">
      <mat-form-field appearance="fill">
        <mat-label>Select Organisations</mat-label>
        <mat-select
          [(value)]="selectedOrg"
          (selectionChange)="setOrg()"
          placeholder="Please select"
        >
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let item of orgList" [value]="item._id">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="dates_wrap-inner">
      <mat-form-field appearance="fill">
        <mat-label>Select User</mat-label>
        <mat-select [(value)]="selectedUser">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let item of userList" [value]="item._id"
            >{{ item.firstName }} {{ item.lastName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="dates_wrap-inner">
      <div id="startDate">
        <mat-form-field appearance="fill">
          <mat-label>From Date</mat-label>
          <input
            matInput
            [matDatepicker]="frompicker"
            readonly
            [max]="todayDate"
            [(ngModel)]="fromDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="frompicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #frompicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="dates_wrap-inner">
      <div id="endDate">
        <mat-form-field appearance="fill">
          <mat-label>To Date</mat-label>
          <input
            matInput
            [matDatepicker]="topicker"
            readonly
            [max]="todayDate"
            [(ngModel)]="toDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="topicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #topicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="dates_wrap-inner">
      <button
        class="filterBtn"
        mat-button
        color="primary"
        (click)="setFilter()"
      >
        Filter
      </button>
    </div>
  </div>

  <canvas
    baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
  >
  </canvas>
</div>
