import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormatTimePipe, OtpSigninComponent } from './otp-signin/otp-signin.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CdTimerModule } from 'angular-cd-timer';
import { ErrorInterceptor } from './Authenticate/error.interceptor';
import { MyCommonModule } from './Modules/my-common/my-common.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgOtpInputModule } from  'ng-otp-input';
import { SupportComponent } from './support/support.component';
import { ToastrModule } from 'ngx-toastr';
import { UserInterceptor } from './Authenticate/user.interceptor';
import { TrendsComponent } from './trends/trends.component';

@NgModule({
    declarations: [
        AppComponent,
        SupportComponent,
        OtpSigninComponent,
        FormatTimePipe,
        TrendsComponent

        

    ],
    imports: [
        BrowserModule,
        MyCommonModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
          }),
        HttpClientModule,
        BrowserAnimationsModule,
        NgOtpInputModule,
        CdTimerModule,
        NgHttpLoaderModule.forRoot()
        
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, }, 
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true} ],
    bootstrap: [AppComponent]
})
export class AppModule { }
