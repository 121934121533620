
            <div id="footer">
                <div class="footer_inner">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                Copyright © {{date}} Saleslabb All rights reserved.
                                 <a data-toggle="modal" data-target="#privacyModal" (click)="openDialog('privacyModal')">Click here</a> to view terms.
                            </div>
                        </div>
                    </div>      
                </div>
             
            </div>
            <div class="modal fade" id="privacyModal" tabindex="-1" role="dialog" aria-labelledby="privacyModal" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="cancelModal">Terms and Conditions</h5>
                            <a  class="close" data-dismiss="modal" aria-label="Close"  (click)="closeDialog('privacyModal')">
                                        <span aria-hidden="true">×</span>
                                    </a>
                        </div>
                        <div class="modal-body">
                            <article class="mt-5">
                                <h2 style="text-align: center;"><strong>TERMS OF SERVICE</strong></h2>
                                <p>Welcome to www.saleslabb.com (“Company”, “we”, “our”, “us”)! These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at <a href="http://www.saleslabb.com">www.saleslabb.com</a> (“Service”) owned and operated by Vita Virtues LLC.</p>

                                <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here www.saleslabb.com/privacy. </p>

                                <p>The Company provides the Services through our Website. By accessing the Services through the Website, immediately and from your very first access of the Services, you unconditionally and irrevocably agree to be bound by these Terms and Conditions, all applicable laws and/or regulations and you agree that you are responsible for this compliance.</p>

                                <p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound by them. </p>

                                <p><strong>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service. These Terms apply to all visitors, users and others who wish to access or use Service.</strong></p>

                                <p>The Company reserves the right to change these Terms and Conditions. These changes are effective immediately upon posting. When changes come into effect, the revised Terms and Conditions shall supersede the previous version of the Terms and Conditions. You are responsible for regularly reviewing these Terms and Conditions.</p>

                                <p>Thank you for being responsible.</p>

                                <ol style="padding-left: 0px;">
                                    <li>
                                        <strong>USE OF WEBSITE</strong>

                                        <ol>
                                            <li>
                                                You may not use the Website to transmit, distribute, store or destroy any material or information (i) in violation of any applicable law or regulation; (ii) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of any third party or violate the privacy or other personal rights of third parties; or (iii)  that is defamatory, obscene, threatening, abusive or hateful.
                                            </li>
                                            <li>
                                                When accessing the Website, you shall be prohibited from:

                                                <ol>
                                                    <li>
                                                        using any robot, spider, other automatic device or manual process to monitor or copy any part of the Website;
                                                    </li>
                                                    <li>
                                                        using any device, software or the like to interfere or attempt to interfere with the proper functioning of the Website;
                                                    </li>
                                                    <li>
                                                        taking any action that imposes an unreasonable or disproportionately large data load on the Website infrastructure;
                                                    </li>
                                                    <li>
                                                        copying, reproducing, altering, modifying, creating derivative works, or publicly displaying any content from the Website without the Company’s prior written consent;
                                                    </li>
                                                    <li>
                                                        reverse assembling, reverse engineering or otherwise attempting to discover any source code relating to the Website or any tool therein, except to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation; and
                                                    </li>
                                                    <li>
                                                        attempting to access any area of the Website to which access is not authorised.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>ELIGIBILITY</strong>

                                        <ol>
                                            <li>
                                                You are at least 18 years of age.
                                            </li>
                                            <li>
                                                You use the Website and Services according to these Terms and all applicable laws and regulations determined by the state and country of residence.
                                            </li>
                                            <li>
                                                You provide complete and accurate registration information and maintain accurate registration information on the Website.
                                            </li>
                                            <li>
                                                You agree and understand that Saleslabb may, at any time, and without prior notice, revoke and/or cancel your access if you fail to meet these criteria or violate any portion of these Terms.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>GENERAL UNDERTAKING</strong>
                                        <ol>
                                            <li>
                                                When you use our Service you accept that you will not:
                                                <ol>
                                                    <li>
                                                        breach any applicable laws or regulations that apply;
                                                    </li>
                                                    <li>
                                                        breach any of the Terms;
                                                    </li>
                                                    <li>
                                                        post or take any threatening, abusive, defamatory, obscene, inappropriate action or post any indecent material, or material or action that is invasive of another’s privacy (including but not limited to other User and our employees);
                                                    </li>
                                                    <li>
                                                        share or otherwise upload or communicate any misleading or false information or messages of any kind;
                                                    </li>
                                                    <li>
                                                        use our Service to intentionally deceive other Users;
                                                    </li>
                                                    <li>
                                                        use our Service to stalk or otherwise harass another User;
                                                    </li>
                                                    <li>
                                                        use our Service if we have suspended or banned you from using it.
                                                    </li>
                                                    <li>
                                                        infringe the Intellectual Property Rights of any third-party;
                                                    </li>
                                                    <li>
                                                        distribute any viruses or any other technologies that may harm our Service or its Users
                                                    </li>
                                                    <li>
                                                        interfere with the property working of our Service
                                                    </li>
                                                    <li>
                                                        copy modify, or distribute any other person’s Content without their consent use any robot, spider, scraper or other automated means to access our Service and/or collect Content or data for any purpose;
                                                    </li>
                                                    <li>
                                                        collect information about other Users, including email addresses, postal addresses, phone numbers, credit card or banking information or similar information without their explicit consent;
                                                    </li>
                                                    <li>
                                                        copy, modify or distribute rights or Content or collect data from our Service, applications or tools or otherwise infringe Saleslabb’s or User’s Intellectual Property Rights;
                                                    </li>
                                                    <li>
                                                        bypass, disable or attempt to disable any security measures used in connection with our Service;
                                                    </li>
                                                    <li>
                                                        collect any data (including personal data) from our Service other than in accordance with these Terms and applicable laws;
                                                    </li>
                                                    <li>
                                                        sell any counterfeit Items or otherwise infringe the copyright, trademark or other rights of third parties;
                                                    </li>
                                                    <li>
                                                        write and post reviews that are anything other than true and accurate to the best of your knowledge; or
                                                    </li>
                                                    <li>
                                                        impersonate any person or misrepresent your affiliation with a person or entity.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Saleslabb shall not be liable in the event that a website’s performance is down or on maintenance or creates any other difficulties to use or access the website or your personal account due to different circumstance which are by any other event, condition or cause is not foreseeable and is beyond the reasonable control of the Saleslabb.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>COMMUNICATIONS</strong>

                                        <ol>
                                            <li>
                                                By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send.
                                            </li>
                                            <li>
                                                You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at support@vitavirtues.com.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>ACCESS AND USE</strong>
                                        <ol>
                                            <li>
                                                You must only use the Service in accordance with these Terms and any applicable law.
                                            </li>
                                            <li>
                                                You must not (or attempt to):

                                                <ol>
                                                    <li>
                                                        Interfere with or disrupt the Service or the server or networks that host the Site;
                                                    </li>
                                                    <li>
                                                        Use data mining, robots, screen scraping or similar data gathering and extraction tools on the Site; or
                                                    </li>
                                                    <li>
                                                        Interfere with security-related or other features of the Service.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                We do not warrant that the Service will be available at all times and without disruption and we provide no warranties in relation to the content of any other website linked to or from our Service.
                                            </li>
                                            <li>
                                                You must not link to our Service or any part of our Service in a way that damages or takes advantage of our reputation, including but not limited to:

                                                <ol>
                                                    <li>
                                                        In a way to suggest or imply that you have any kind of association and affiliation with us, or approval and endorsement from us when there is none; or
                                                    </li>
                                                    <li>
                                                        In a way that is illegal or unfair.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>PROHIBITED USES </strong>

                                        <ol>
                                            <li>
                                                You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
                                                <ol>
                                                    <li>
                                                        In any way that violates any applicable national or international law or regulation.
                                                    </li>
                                                    <li>
                                                        For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
                                                    </li>
                                                    <li>
                                                        To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
                                                    </li>
                                                    <li>
                                                        To impersonate or attempt to impersonate the Company, the Company employee, another user, or any other person or entity.
                                                    </li>
                                                    <li>
                                                        In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
                                                    </li>
                                                    <li>
                                                        To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend the Company or users of Service or expose them to liability.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Additionally, you agree not to:
                                                <ol>
                                                    <li>
                                                        Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.
                                                    </li>
                                                    <li>
                                                        Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.
                                                    </li>
                                                    <li>
                                                        Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.
                                                    </li>
                                                    <li>
                                                        Use any device, software, or routine that interferes with the proper working of Service.
                                                    </li>
                                                    <li>
                                                        Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
                                                    </li>
                                                    <li>
                                                        Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
                                                    </li>
                                                    <li>
                                                        Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
                                                    </li>
                                                    <li>
                                                        Take any action that may damage or falsify the Company rating.
                                                    </li>
                                                    <li>
                                                        Otherwise attempt to interfere with the proper working of Service.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>ACCOUNTS</strong>
                                        <ol>
                                            <li>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</li>
                                            <li>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. </li>
                                            <li>You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. </li>
                                            <li>You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</li>
                                            <li>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization.</li>
                                            <li>You may not use as a username any name that is offensive, vulgar or obscene.</li>
                                            <li>We reserve the right to refuse service, terminate accounts, remove or edit content in our sole discretion.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>REGISTRATION, LOGIN CREDENTIALS AND SECURITY </strong>
                                        <ol>
                                            <li>In order to be able to make use of and access the Services, you shall register on the Service by providing the Company with all required information which includes but is not limited to your full name, business name, address and contact information. </li>
                                            <li>You agree to (i) provide true, accurate, current and complete information about yourself as prompted by the sign-up process; and (ii) maintain and promptly update the information provided during registration to keep it true, accurate, current, and complete. </li>
                                            <li>Each Saleslabb account can be accessed by its/his/her holder via the Service (“Account”).</li>
                                            <li>If you provide any information to the Company that is untrue, inaccurate, outdated or incomplete, or if we have reasonable grounds to suspect that such information is untrue, inaccurate, outdated or incomplete, we may terminate your Account with us and refuse current or future use of any or all of the Services and Saleslabb.</li>
                                            <li>When registering an Account as a user on the Site, you can create Account password and username with your choice (“Login Credentials”).</li>
                                            <li>
                                                You hereby:
                                                <ol>
                                                    <li>acknowledge and agree that you are solely responsible for maintaining the confidentiality of your Login Credentials and for any and all activities that occur under your account;</li>
                                                    <li>agree to notify the Company immediately of any unauthorized use of your account or of any other breach of security. The Company shall not be liable for any loss that you may incur as a result of any third party using your Login Credentials or Account, either with or without your knowledge. However, you may be held liable for losses incurred by the Company as a result of a third party using your Account or Login Credentials. </li>
                                                    <li>acknowledge and agree that you may not use anyone else's Account at any time, without the express permission of such account holder.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>PURCHASES</strong>
                                        <ol>
                                            <li>If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</li>
                                            <li>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</li>
                                            <li>We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. </li>
                                            <li>By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</li>
                                            <li>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</li>
                                            <li>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>SUBSCRIPTIONS </strong>
                                        <ol>
                                            <li>Some parts of Service are billed on a subscription basis (“Subscription(s)”). </li>
                                            <li>You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). </li>
                                            <li>Billing cycles are set on a monthly basis.</li>
                                            <li>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Saleslabb cancels it. </li>
                                            <li>You may cancel your Subscription renewal either through your online account management page or by contacting Saleslabb customer support team.</li>
                                            <li>A valid payment method, including credit card, is required to process the payment for your subscription. </li>
                                            <li>You shall provide Saleslabb with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. </li>
                                            <li>By submitting such payment information, you automatically authorize Saleslabb to charge all Subscription fees incurred through your account to any such payment instruments.</li>
                                            <li>Should automatic billing fail to occur for any reason, Saleslabb will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>FREE TRIAL</strong>
                                        <ol>
                                            <li>Saleslabb may at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”).</li>
                                            <li>You may be required to enter your billing information in order to sign up for Free Trial. </li>
                                            <li>If you do enter your billing information when signing up for Free Trial, you will not be charged by Saleslabb until Free Trial has expired. </li>
                                            <li>On last day of Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</li>
                                            <li>At any time and without notice, Vit Saleslabb serves the right to (i) modify Terms of Serviece of Free Trial offer, or (ii) cancel such Free Trial offer.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>FEE CHANGES </strong>
                                        <ol>
                                            <li>Saleslabb, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. </li>
                                            <li>Any Subscription fee change will become effective at the end of the then-current Billing Cycle. </li>
                                            <li>Saleslabb will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective. </li>
                                            <li>Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>REFUNDS</strong>
                                        <ol>
                                            <li>We issue refunds for Contracts after (30) days of the original purchases of the Subscription. </li>
                                            <li>We may make an administrative charge to cover unreasonably disputed credit card payments. </li>
                                            <li> In circumstances where a payment is returned:
                                                <ol>
                                                    <li>We reserve the right to claim the amount of the returned payment plus interest. </li>
                                                    <li>Refunds are issued in the form in which the original payment was made. </li>
                                                    <li>We will process any refund within 14 days of notifying you that we are issuing you the refund.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>SAFETY</strong>
                                        <ol>
                                            <li>User will not (nor assist others to) violate any applicable law, contract, intellectual property, or other third-party right, and User is solely responsible for its conduct while using our Services. </li>
                                            <li>User must not directly, indirectly, or through automated or other means:
                                                <ol>
                                                    <li>engage in any harassing, threatening, intimidating, predatory, or stalking conduct; </li>
                                                    <li>use or attempt to use another user’s account without prior authorization from that user and Saleslabb; </li>
                                                    <li>impersonate or register on behalf of any person or entity or otherwise misrepresent your affiliation with a person or entity, perpetrate fraud, or publish falsehoods or misleading statements; </li>
                                                    <li>collect information of or about other users in any impermissible or unauthorized manner; </li>
                                                    <li>use our Services other than for their intended purpose or interfere with, disrupt, negatively affect, or inhibit other users; </li>
                                                    <li>damage, disable, overburden, or impair our Services; </li>
                                                    <li>send, distribute, or post spam, unsolicited electronic communications, chain letters, pyramid schemes, or illegal or impermissible communications; </li>
                                                    <li>post, upload, or share any content which is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent, or is in our sole judgment objectionable;</li>
                                                    <li>encourage or provide instructions for a criminal offense; </li>
                                                    <li>distribute any viruses, corrupted data, or other harmful, disruptive, or destructive files or content; </li>
                                                    <li>bypass, ignore, or circumvent instructions in our robots.txt file or any measures we employ to prevent or limit access to any part of our Services, including content-filtering techniques; or </li>
                                                    <li>expose Saleslabb or others to any type of harm or liability.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>NO USE BY MINORS</strong>
                                        <ol>
                                            <li>Service is intended only for access and use by individuals at least (18) years old. </li>
                                            <li>By accessing or using any of the Company, you warrant and represent that you are at least (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. </li>
                                            <li>If you are not at least (18) years old, you are prohibited from both the access and usage of Service.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>CONTENT</strong>
                                        <ol>
                                            <li>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.</li>
                                            <li>By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</li>
                                            <li>You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.</li>
                                            <li>The Company has the right but not the obligation to monitor and edit all Content provided by users.</li>
                                            <li>In addition, Content found on or through this Service are the property of the Company or used with permission. </li>
                                            <li>You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>INTELLECTUAL PROPERTY </strong>
                                        <ol>
                                            <li>Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Saleslabb and its licensors. </li>
                                            <li>Service is protected by copyright, trademark, and other laws of British Virgin Islands. </li>
                                            <li>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Saleslabb.</li>
                                            <li>All code, text, software, scripts, graphics, files, photos, images, logos, and materials contained on this website, our applications, or within the services, are the sole property of Saleslabb.</li>
                                            <li>Unauthorized use of any materials contained on this Website, our applications or within the Service may violate copyright laws, trademark laws, the laws of privacy and publicity, and/or other regulations and statutes. If you believe that any of the materials infringe on any third party's rights, please contact Saleslabb immediately at: support@vitavirtues.com</li>
                                            <li>All content, trademarks, data, information or information contained in any materials, or documents used in relation to the Company and the Services, including but not limited to any and all copyrighted works, software, databases, text, graphics, icons, designs, logos, graphics, hyperlinks, domain names, information and agreements (“Content”), are the exclusive property of or are licensed to the Company. </li>
                                            <li>You may not reproduce, distribute or copy the Content by any means, whether electronically or not, without the Company’s prior written permission.</li>
                                            <li>Any and all intellectual property rights in the Content, Services and the Company or otherwise developed by or on behalf of the Company, including all patents, rights in inventions, rights in designs, trademarks, trade and business names and all associated goodwill, rights to sue for passing off or for unlawful competition, copyright, moral rights and related rights, rights in databases, topography rights, domain names, rights in information (including know how and trade secrets) and all other similar or equivalent rights existing in the Content, Services and the Company or otherwise developed by or on behalf of the Company, now or in the future in any part of the world, in each case whether registered or unregistered and including all applications for, and renewals or extensions of, such rights for their full term (“Intellectual Property”), vests in the Company, its licensors or suppliers, as the case may be, and all rights not expressly granted by the Company to you are reserved by the Company.</li>
                                            <li>Save as expressly set out herein and in the Contracts, you shall not acquire any right, title or interest in the Company’s Intellectual Property. </li>
                                            <li>You may not use the Company’s Intellectual Property in a manner which may (i) place the Company’s Intellectual Property at risk of losing value, and (ii) cause reputational damage to the Company.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>ERROR REPORTING AND FEEDBACK </strong>
                                        <ol>
                                            <li>You may provide us either directly at support@vitavirtues.com or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). </li>
                                            <li>You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) the Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) the Company is not under any obligation of confidentiality with respect to the Feedback. </li>
                                            <li>In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant the Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</li>
                                            <li>Saleslabb shall not be liable for any typo or any information being incorrect in this Agreement, in case you have discovered such type or mistake in wording of this Agreement you may inform us about such mistake directly at <a href="mailto:support@vitavirtues.com">support@vitavirtues.com</a>.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>LINKS TO OTHER WEB SITES</strong>
                                        <ol>
                                            <li>Our Service may contain links to third party web sites or services that are not owned or controlled by Saleslabb.</li>
                                            <li>Saleslabb has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</li>
                                            <li>YOU ACKNOWLEDGE AND AGREE THAT SALESLABB SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</li>
                                            <li>WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>DISCLAIMER OF WARRANTY </strong>
                                        <ol>
                                            <li>THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</li>
                                            <li>NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</li>
                                            <li>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</li>
                                            <li>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>LIMITATION OF LIABILITY </strong>
                                        <ol>
                                            <li>
                                                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF THE COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF THE COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>TERMINATION </strong>
                                        <ol>
                                            <li>We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</li>
                                            <li>If you wish to terminate your account, you may simply discontinue using Service.</li>
                                            <li>All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>GOVERNING LAW</strong>
                                        <ol>
                                            <li>These Terms shall be governed and construed in accordance with the laws of British Virgin Islands without regard to its conflict of law provisions.</li>
                                            <li>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. </li>
                                            <li>If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. </li>
                                            <li>These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</li>
                                            <li>Each party irrevocably agrees that the courts of British Virgin Islands shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes or claims).</li>
                                            <li>Notwithstanding the specified agreement on jurisdiction, you and the Company shall, if any dispute arises, attempt to settle it by mutual negotiations.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>FORCE MAJEURE</strong>
                                        <ol>
                                            <li>
                                                Neither Party shall be liable in the event that a Party’s performance of this Agreement is prevented, or rendered so difficult or expensive as to be commercially impracticable, by reason of an Act of God, epidemic, pandemic, labor dispute, website’s maintenance, governmental restrictions or actions, war (declared or undeclared) or other hostilities, or by any other event, condition or cause which is not foreseeable and is beyond the reasonable control of the Party.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>DISCLAIMER ON ACCURACY OF CONTENT</strong>
                                        <ol>
                                            <li>You acknowledge that Saleslabb has no duty or obligation to any generated output by our Services to be accurate, current, or complete and agree that your reliance on any such generated output is at your own risk. </li>
                                            <li>Saleslabb makes best efforts to maintain the accuracy of our Services, it does not represent or warrant that they are error-free.</li>
                                            <li>Saleslabb does not control final output generated by our Service. </li>
                                            <li>You hereby agree and acknowledged that Saleslabb under no circumstances will be liable in any way for any errors or omissions in the final output.  </li>
                                            <li>Saleslabb has no responsibility for any errors or omissions therein. </li>
                                            <li>Saleslabb is not liable for any loss or damage of any kind you may claim was incurred as a result of the use of final output generated by our Service.</li>
                                            <li>You hereby agree and understand that you are held personally liable for any error or omission in the final output generated by our Service.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>CHANGES TO SERVICE </strong>
                                        <ol>
                                            <li>We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. </li>
                                            <li>We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. </li>
                                            <li>From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>AMENDMENTS TO TERMS </strong>
                                        <ol>
                                            <li>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</li>
                                            <li>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. </li>
                                            <li>You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</li>
                                            <li>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. </li>
                                            <li>If you do not agree to the new terms, you are no longer authorized to use Service.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>INDEMNITY</strong>
                                        <ol>
                                            <li>
                                                You agree to indemnify, defend and hold harmless us and our related entities, affiliates, and our and their respective officers, agents, and employees, harmless from and against any and all claims demands (actual, special and consequential) of every kind and nature, known and unknown, including reasonable legal fees, made by any third party due to or arising out of your breach of these terms or your breach of any law or the right of third party.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>WAIVER AND SEVERABILITY </strong>
                                        <ol>
                                            <li>No waiver by the Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</li>
                                            <li>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>ACKNOWLEDGEMENT </strong>
                                        <ol>
                                            <li>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>CONTACT US</strong>
                                        <ol>
                                            <li>
                                                For more information about our Term and Conditions, if you have questions, or if you would like to make a complaint, please send your feedback, comments, requests for technical support: by email: support@vitavirtues.com
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                            </article>
                        </div>
                        <div class="modal-footer">
                            <!-- <a href="#" class="btn btn-secondary" data-dismiss="modal">Don't Agree</a> -->
                            <a href="#"  data-dismiss="modal" class="btn btn-primary" (click)="closeDialog('privacyModal')">Close</a>
                        </div>
                    </div>
                </div>
            </div>