import { Component, OnInit } from '@angular/core';

import { CustomersService } from '../Components/customer-details/customers.service';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private custServ:CustomersService) { }

  ngOnInit(): void {
    // this.custServ.setLogoValue(false);
    // this.custServ.login.next(false)
  }

  sidbar: boolean = false;
  sidbarToggle(){
        this.sidbar = !this.sidbar;       
    }
    step = 0;

    setStep(index: number) {
      this.step = index;
    }
  
    nextStep() {
      this.step++;
    }
  
    prevStep() {
      this.step--;
    }
}
