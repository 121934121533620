import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';


@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
    //Line Chart Configuration
    lineChartData: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product A', fill: false },
        { data: [28, 48, 40, 19, 86, 27, 90], label: 'Product B', fill: false },
        { data: [29, 40, 50, 29, 85, 25, 80], label: 'Product C', fill: false }
    ];

    lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    lineChartOptions: ChartOptions = {
        responsive: true
    };

    lineChartColors: Color[] = [
        {
            backgroundColor: 'rgba(77,83,96,0.2)',
            borderColor: 'orange',
        },
        {
            backgroundColor: 'rgba(255,0,0,0.3)',
            borderColor: 'red',
        },
        {
            backgroundColor: 'yellow',
            borderColor: 'green',
        }
    ];

    lineChartLegend = true;
    lineChartType: ChartType = 'line';
    lineChartPlugins = [];
    // Line Chart Configuration

    // Bar Chart Configuration

    public barChartOptions: ChartOptions = {
        responsive: true,
    };
    public barChartLabels: Label[] = ['2015', '2016', '2017', '2018', '2019', '2020'];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [];

    public barChartData: ChartDataSets[] = [
        { data: [65, 67, 70, 75, 80, 90], label: 'A', fill: false },
        { data: [50, 48, 47, 49, 44, 40], label: 'B', fill: false },
        { data: [40, 30, 28, 25, 22, 20], label: 'C', fill: false },
    ];

    //Pie chart
    pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'top',
        },
    };

    pieChartLabels: Label[] = ['Nitrogen', 'Oxygen', 'Argon', 'Carbon dioxide'];

    pieChartData: number[] = [78.09, 20.95, 0.93, 0.03];

    pieChartType: ChartType = 'pie';

    pieChartLegend = true;

    pieChartPlugins = [];

    pieChartColors = [
        {
            backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
        },
    ];
    // events
    chartClicked(event: any) {
    }

    chartHovered(event: any) {

    }
    constructor() { }

    ngOnInit(): void { }
}
