<div class="graph_pg-wrapper">
    <div class="graph_pg-inner">
        <div class="dates_wrapper-outer">
            <h2>{{heading}}</h2>
            
            <div class="dates_wrapper">
                <mat-form-field appearance="fill" *ngIf="chartsBy == 'users'">
                    <mat-label>Select Organisations</mat-label>
                    <mat-select multiple [(ngModel)]="selectOrg">
                        <mat-option (click)="changeOrg('all')"  [value]="'all'">All</mat-option>

                        <mat-option *ngFor="let item of orgData" (click)="changeOrg(item.name)" [value]="item.name"> {{
                            item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Charts by</mat-label>
                    <mat-select [(ngModel)]="chartsBy" (selectionChange)="changeCharts(chartsBy)"
                        placeholder="Please select">
                        <mat-option value="organisations">Organisations</mat-option>
                        <mat-option value="users">Users</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="dates_wrap-inner">
                    <div id="startDate">
                        <mat-form-field appearance="fill">
                            <mat-label>From Date</mat-label>
                            <input matInput [matDatepicker]="frompicker" readonly [max]="todayDate"
                                [(ngModel)]="fromDate">
                            <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                            <mat-datepicker #frompicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dates_wrap-inner">
                    <div id="endDate">
                        <mat-form-field appearance="fill">
                            <mat-label>To Date</mat-label>
                            <input matInput [matDatepicker]="topicker" readonly [max]="todayDate" [(ngModel)]="toDate">
                            <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                            <mat-datepicker #topicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dates_wrap-inner">
                    <button class="filterBtn" mat-button color="primary" (click)="setFilter()">Filter</button>
                </div>
                <div class="dates_wrap-inner">
                    <button class="filterBtn" mat-button color="primary" (click)="resetFilters()">Reset Filters</button>
                </div>
            </div>
        </div>
        <div style="padding: 0 20px 0">
            <h2>Quotations Count: <span>{{quoteCount}}</span></h2>
        </div>

        <!-- <div>
            <canvas width="300" height="80" baseChart [datasets]="lineChartData" [labels]="lineChartLabels"
                [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
            </canvas>
        </div> -->
        <div class="graph_wg-wrapper">
            <div class="graph_wg-inner">
                <canvas baseChart width="300" height="80" [datasets]="barChartData" [labels]="barChartLabels"
                    [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                    [chartType]="barChartType">
                </canvas>
            </div>
        </div>
    </div>
</div>