
<main class="dashboard_content-wrapper">
  <div class="dashboard_content-inner">
      <div class="dashboard_content-breadcum">
          <div class="dashboard_content-breadcum-inner">
              <h4 class="breadcum-text">Dashboard</h4>
          </div>
          <div class="dashboard_content-breadcum-inner">
              <!-- top content -->
          </div>
      </div>
      <div class="dashboard_content-main">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-md-8 p-0">
                      <div class="dashboard_content-accos">
                          <mat-accordion>
                              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" class="mb-3">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    Quick Stats
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                            
                                <div class="quick_stats-wrapper">
                                    <div class="quick_stats-inner">
                                        <div class="quick_stats-box total_quot">
                                            <h4 class="quick_stats-label">Total quotations</h4>
                                            <div class="quick_stats-numb-wrap">
                                                <span class="quick_stats-val">0</span>
                                                <span class="quick_stats-val-label green">+0.00%</span>
                                            </div>
                                        </div>
                                        <div class="quick_stats-box open_quot">
                                            <h4 class="quick_stats-label">Total quotations</h4>
                                            <div class="quick_stats-numb-wrap">
                                                <span class="quick_stats-val">0</span>
                                                <span class="quick_stats-val-label green">+0.00%</span>
                                            </div>
                                        </div>
                                        <div class="quick_stats-box sales_month">
                                            <h4 class="quick_stats-label">Total quotations</h4>
                                            <div class="quick_stats-numb-wrap">
                                                <span class="quick_stats-val">0</span>
                                                <span class="quick_stats-val-label red">+0.00%</span>
                                            </div>
                                        </div>
                                        <div class="quick_stats-box new_reqts">
                                            <h4 class="quick_stats-label">Total quotations</h4>
                                            <div class="quick_stats-numb-wrap">
                                                <span class="quick_stats-val">0</span>
                                                <span class="quick_stats-val-label"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                              </mat-expansion-panel>
                              
                              <mat-expansion-panel class="mb-3">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    New Request
                                  </mat-panel-title>
                                  <!-- <mat-panel-description>
                                    Type the country name
                                    <mat-icon>map</mat-icon>
                                  </mat-panel-description> -->
                                </mat-expansion-panel-header>
  
                                <div class="add-request-wrapper">
                                    <div class="add-request-inner">
                                      <mat-panel-title>
                                          Add Request
                                        </mat-panel-title>
                                        <div class="d-flex flex-wrap">
                                          <div class="field-wrap">
                                            <mat-form-field>
                                                <mat-label>Name</mat-label>
                                                <input matInput>
                                            </mat-form-field>
                                          </div>
                                          <div class="field-wrap">
                                            <mat-form-field>
                                                <mat-label>Phone</mat-label>
                                                <input matInput>
                                            </mat-form-field>
                                          </div>
                                          <div class="field-wrap">
                                            <mat-form-field>
                                                <mat-label>Email</mat-label>
                                                <input matInput>
                                            </mat-form-field>
                                          </div>
                                          <div class="btn-wrap">
                                            <button class="btn btn-primary" mat-button color="primary">Save</button>
                                          </div>

                                        </div>
                                    </div>
                                </div>
                                
                              </mat-expansion-panel>
                            
                              <mat-expansion-panel class="mb-3">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    Pending quotations
                                  </mat-panel-title>
                                  <!-- <mat-panel-description>
                                    Inform the date you wish to travel
                                    <mat-icon>date_range</mat-icon>
                                  </mat-panel-description> -->
                                </mat-expansion-panel-header>
  
                              </mat-expansion-panel>

                              <mat-expansion-panel class="mb-3">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    Insurance providers
                                  </mat-panel-title>
                                  <!-- <mat-panel-description>
                                    Inform the date you wish to travel
                                    <mat-icon>date_range</mat-icon>
                                  </mat-panel-description> -->
                                </mat-expansion-panel-header>

                                <div class="inspro_wrapper">
                                  <div class="inspro_wrapper-inner">

                                    <div class="inspro_wrapper-item">
                                      <div class="inspro_logowrap">
                                        <!-- <img src="" /> -->
                                      </div>
                                      <div class="inspro_details">
                                        <span>info@axa.com</span>
                                        <span>+971 54 333 6652</span>
                                      </div>
                                    </div>

                                    <div class="inspro_wrapper-item">
                                      <div class="inspro_logowrap">
                                        <!-- <img src="" /> -->
                                      </div>
                                      <div class="inspro_details">
                                        <span>info@axa.com</span>
                                        <span>+971 54 333 6652</span>
                                      </div>
                                    </div>

                                    <div class="inspro_wrapper-item">
                                      <div class="inspro_logowrap">
                                        <!-- <img src="" /> -->
                                      </div>
                                      <div class="inspro_details">
                                        <span>info@axa.com</span>
                                        <span>+971 54 333 6652</span>
                                      </div>
                                    </div>

                                    <div class="inspro_wrapper-item">
                                      <div class="inspro_logowrap">
                                        <!-- <img src="" /> -->
                                      </div>
                                      <div class="inspro_details">
                                        <span>info@axa.com</span>
                                        <span>+971 54 333 6652</span>
                                      </div>
                                    </div>

                                  </div>
                                </div>
  
                              </mat-expansion-panel>


                          </mat-accordion>
                      </div>
                  </div>
                  <div class="col-md-4 p-0">
                      <div class="dashboard_content-sidbar right">
                          <div class="dashboard_content-sidbar-header">
                              <span>Tasks</span>
                              <span class="show-text">Show all</span>
                          </div>
                          <div class="dashboard_content-sidbar-body">
                            <div class="body-heading">
                              <span class="material-icons">add_task</span>
                              <span>Add a task</span>
                            </div>
                          </div>
                          <div class="dashboard_content-sidbar-footer">
                              <mat-accordion>
                                  <mat-expansion-panel class="mb-3">
                                    <mat-expansion-panel-header>
                                      <mat-panel-title>
                                        Completed Tasks
                                      </mat-panel-title>
                                      <!-- <mat-panel-description>
                                        Inform the date you wish to travel
                                        <mat-icon>date_range</mat-icon>
                                      </mat-panel-description> -->
                                    </mat-expansion-panel-header>
      
                                  </mat-expansion-panel>
                              </mat-accordion>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>