import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiService } from '../service/api.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authenticateService: ApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      if(err.status == 401 && !window.location.href.includes('/login')) {
        this.authenticateService.logout();
        location.reload();
      }

      const error = err.error.message || err.statusText || err.error.message;
      return throwError(error);
    }));
  }
}
