import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root'
})
export class UsersGuard implements CanActivate {
  usertype: any
  constructor(private role: AuthGuard, private auth: AuthenticationService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isauthorized(route)
  }

  private isauthorized(route: ActivatedRouteSnapshot): boolean {

    this.usertype = this.auth.getUser();
    const expectedRoles = route.data.expectedRoles;
    if (expectedRoles[0] == this.usertype.type || expectedRoles[1] == this.usertype.type) {
      return true;
    } else {
      this.router.navigate(['/dashboard'])
      return false;

    }
  }
}
