import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Organisation } from './organisation';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OrgserviceService {


  id!: string;
  constructor(private http: HttpClient, private toaster: ToastrService) { }
  private organisationGlobalList: Organisation[] = [];

  private orgListData: any[] = [];

  public organisationName: string = '';
  public list = new BehaviorSubject(123);
  data: any
  public closeBtn = new Subject();
  public orgReset = new Subject();
  // public Base_Url = environment.BaseURL
  // public Base_URl = 'http://localhost:3007';
  // public Base_Url = environment.BaseURL
  public Base_Url = environment.BaseURL;
  public orl_url = environment.OldBaseURL
  private headers = new HttpHeaders().append('content-type', 'application/json');




  getItem() {
    return this.http.get<Organisation>(this.Base_Url + '/organisation').pipe(
      map((data: Organisation) => {
        // this.toaster.success('Organisation List Displayed Successfully')
        return data;
      }), catchError(error => {
        this.toaster.error('Cannot display organisation List')
        return error;
      })
    )
  }

  sendFormdata(data: Organisation) {
    let params = new HttpParams();
    params.append('id', data.id);
    const body = JSON.stringify(data);
    return this.http.post(this.Base_Url + '/organisation', body, { 'headers': this.headers, params: params }).subscribe(res => {
      if (res) {
        this.toaster.success('Organisation Created SuccessFully')
        this.orgReset.next(true);

      } else {
        this.toaster.error('Organisation is not created')
      }
    })

  }

  // getFormdata(): any {


  //   this.getItem().subscribe((res: any) => {
  //     this.organisationGlobalList = res;
  //     console.log("response global", this.organisationGlobalList);
  //     this.list.next(res);
  //     return this.organisationGlobalList;
  //   })



  // }



  getOrganisationData(id: string): Organisation | undefined {


    return this.organisationGlobalList.find(x => x.id == id);
  }

  sendOrganisation(data: any) {

    this.organisationName = data;
  }

  getOrganisation() {
    return this.organisationName;
  }

  sendOrgList(data: any) {
    this.orgListData.push(data);
  }

  getOrgList() {
    return this.orgListData;
  }

  getOrganisationIdName() {
    return this.organisationGlobalList.map(x => { return { "key": x.id, "value": x.name, "isSelected": false } })
  }

  updateOrganisation(data: Organisation) {

    const body = JSON.stringify(data);

    return this.http.put(this.Base_Url + '/organisation/' + data._id, body, { 'headers': this.headers }).subscribe(res => {
      if (res) {
        this.orgReset.next(true);
        this.toaster.success('Organisation Updated SuccessFully')
      } else {
        this.toaster.error('Organisation is not Updated')
      }


    })

  }

  deleteOrganisation(data: Organisation) {


    return this.http.delete(this.Base_Url + '/organisation/' + data).subscribe(res => {
      if (res) {
        this.orgReset.next(true)
        this.toaster.success('Organisation Deleted Successfully')
      } else {
        this.toaster.error('Cannot Delete Organisation')
      }

    })
  }
  orgStatus(organisation: Organisation) {
    this.data = organisation;
    let org = {
      id: organisation._id,
      organisation: organisation.name,
      status: organisation.status,
      updateStatus: organisation.updateStatus
    }
    return this.http.post(this.Base_Url + '/organisation/change_status', org, { 'headers': this.headers }).subscribe(res => {
      if (res) {
        this.orgReset.next(true)
        this.toaster.success('Status Updated Successfully')
      } else {
        this.toaster.error('Cannot change status')
      }
    })
  }

  // orgbyStatus() {

  //  return this.http.post(this.Base_Url + '/Users/changeStatusByOrganisations', this.data).subscribe(res => {


  //  })
  // }

}

