import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var jQuery: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }
  public date: any;

  ngOnInit(): void {
    var  date = new Date();
    this.date = date.getFullYear();
  }
  openDialog(dialogName:string){
    jQuery('#'+dialogName).modal('show')
  }
  closeDialog(dialogName:string){
    jQuery('#'+dialogName).modal('hide')
  }


}
