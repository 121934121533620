import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomersService } from '../customer-details/customers.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    public isCollapse = true
    cignaLogo:boolean=false;
    login:boolean = false;
    loginSign = true;
    signinsignout = true
    signinData = false;
    currentRole: any;
    adminView = true;
    agentView = true;
    constructor(private router:Router,private custServ:CustomersService, private auth: AuthenticationService) { }

    ngOnInit(): void {
      // if(localStorage.getItem('USER')){
      //   this.cignaLogo = true;
      // }else{
      //   this.cignaLogo = false;

      // }
      
      this.custServ.routes.asObservable().subscribe( res => {
        this.signinsignout = res;
              })
      
     this.auth.loginType.subscribe(res => {
        this.currentRole = res;
        if(this.currentRole == "Admin") {
          this.adminView = false;
        }
        else if(this.currentRole == 'Agent' || this.currentRole == 'Manager' || this.currentRole == 'Admin') {
          this.agentView = false;
          
        }
      })  
     
   
      this.custServ.getInfo.asObservable().subscribe((res) => {
        if(res) {
          this.loginSign = false; 
        } else {
          this.loginSign = true
        } 
      });
      this.custServ.login.asObservable().subscribe((res) => {
        if(res) {
          this.signinData = true;
        } else {
          this.signinData = false;
        }
      })

      this.custServ.getLogoValue().subscribe(
        obj => {
          this.cignaLogo=obj;
  
        });
    }
  
    sidbar: boolean = false;
    sidbarToggle(){
          this.sidbar = !this.sidbar;       
      }
      step = 0;
  
      setStep(index: number) {
        this.step = index;
      }
    
      nextStep() {
        this.step++;
      }
    
      prevStep() {
        this.step--;
      }
      action(value:string){
        if(value=='policy'){
          this.router.navigate(['/customer']);
        }
        this.sidbarToggle();
      }
      viewDash() {
        this.router.navigate(['/dashboard']);
        this.sidbarToggle();
      }
      viewOrganisation(){
        this.router.navigate(['/organisations']);
        this.sidbarToggle();
      }

      viewCompany(){
        this.router.navigate(['/company']);
        this.sidbarToggle();
      }

      viewUser(){
        this.router.navigate(['/user']);
        this.sidbarToggle();
      }

      viewQuotes() {
        this.router.navigate(['/viewqoutes']);
        this.sidbarToggle();

      }
      signin(){
         this.router.navigate(['/login']);
        this.signinsignout = false
      }

      logout(){
         this.router.navigate(['/']);
        localStorage.removeItem('USER');
        this.cignaLogo = false;
        this.custServ.setLogoValue(false);
        this.signinsignout = true;
      }
      privacy() {
        this.router.navigate(['/privacy']);
        this.sidbarToggle();

      }
      support() {
        this.router.navigate(['/support']);
        this.sidbarToggle();

      }

      account(){
        this.router.navigate(['/account']);
        this.sidbarToggle();
      }
}
