import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from '@angular/core';

const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {

    constructor(private httpClient: HttpClient) { }

    getAnalyticData() {
        return this.httpClient
            .get(
                '/api/users/analysis', { 'headers': headers }
            )
            .toPromise();
    }
    getAnalyticDataFiltered(startDate: string, endDate: string) {
        return this.httpClient
            .post(
                '/api/users/filtered', { 'startDt': startDate, 'endDt': endDate }, { 'headers': headers }
            )
            .toPromise();
    }
    getQuotesData(id: any, name: any) {
        return this.httpClient
            .get('/api/users/' + id + '/' + name, { 'headers': headers })
            .toPromise();
    }
    signUp(data: any) {
        return this.httpClient
            .post('/api/authorization/signUp', data, { 'headers': headers })
            .toPromise();
    }
    signIn(data: any) {
        return this.httpClient
            .post('/api/authorization/signin', data, { 'headers': headers })
            .toPromise();
    }
}
//db.quotes.find().forEach(function(x) { db.quotes.update({ "_id":x._id}, {$set: {createDate:new Date(x.createDate) } }) })
