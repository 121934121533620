import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from '@angular/core';

const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

@Injectable({
    providedIn: 'root'
})
export class QuotesService {

    constructor(private httpClient: HttpClient) { }

    getAnalyticquoteData() {
        return this.httpClient
            .get(
                '/api/companies/companyquotes', { 'headers': headers }
            )
            .toPromise();
    }

    getAnalyticquoteDataFilter(data: any) {
        return this.httpClient
            .post(
                '/api/companies/companyquotes/filter', data, { 'headers': headers }
            )
            .toPromise();
    }

    getPlansDistinctInfo(data: any) {  
        return this.httpClient
            .post(
                '/api/companies/companyquotes/plans/distinctInfo', data, { 'headers': headers } 
            )
            .toPromise();
    }
}
//db.quotes.find().forEach(function(x) { db.quotes.update({ "_id":x._id}, {$set: {createDate:new Date(x.createDate) } }) })
