import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomersService } from '../Components/customer-details/customers.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-site',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
    //@ViewChild("createModal") create: TemplateRef<any>;
    insuranceForm: FormGroup;
    inquiryForm: FormGroup;
    brokers = []

    constructor(private fb: FormBuilder, private modalService: NgbModal,private custServ:CustomersService, private router: Router, private route: ActivatedRoute) {
        this.insuranceForm = this.fb.group({
            fName: new FormControl((''), Validators.required),
            lName: new FormControl((''), Validators.required),
            mobile: new FormControl((''), Validators.required)
        })
        this.inquiryForm = this.fb.group({
            inquiryFirstName: new FormControl((''), Validators.required),
            inquiryEmail: new FormControl((''), Validators.required),
            inquiryMobNo: new FormControl((''), Validators.required),
            inquiryMsg: new FormControl((''), Validators.required)


        })
    }

    ngOnInit(): void {
        // this.custServ.setLogoValue(false);
        // this.custServ.getInfo.next(true);
        if(localStorage.getItem('USER')) {
            this.router.navigate(['/dashboard'])
        } else if(localStorage.getItem('')) {
            this.router.navigate(['/'])
        }
    }
    openModal(anyModal: any) {
        this.modalService.open(anyModal, {
            centered: true,
            backdrop: 'static'
        });
    }
    clickToEdit(value: any) {
        //this.openModal(this.create)
    }
    submitInsurance() { }
    sendDetail() { }
}
