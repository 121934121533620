export class Alerts {
    id: string='';
    type: AlertType=0;
    message: string='';
    autoClose: boolean=false;
    keepAfterRouteChange: boolean=false;
    fade: boolean=false;

    constructor(init?:Partial<Alerts>) {
        
        Object.assign(this,init);
    }
}
export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}