import * as moment from 'moment';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AuthenticationService } from '../services/authentication.service';
import { CustomersService } from '../Components/customer-details/customers.service';
import { QuotesService } from './quotes.service';
import { OrgserviceService } from '../Modules/organisations/orgservice.service';
import { UserService } from '../Modules/user/user.service';
import { CompanyService } from '../Modules/company/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss'],
})
export class QuotesComponent implements OnInit {
  selectInsurer: any;
  countryList: any;
  compdata: any;
  selectUsers: any;
  usersList: any;
  quoteUsers: any;
  filters: any;
  organisationList: any[] = [];
  orgData1: any;
  dateOrg: any;
  chartsBy: string = 'organisations';
  selectOrg: any;
  heading: string = 'Insurers based on No. of Quotation';
  quotesData: any;
  quotesArray: any[] = [];
  quotesLabel: any[] = [];
  quotesValue: any[] = [];
  dateQuotesArr: any[] = [];
  dateQuotesLabel: any[] = [];
  dateQuotesValue: any[] = [];
  fromDate: any;
  toDate: any;
  todayDate: Date = new Date();
  selectResidency: any;
  selectPlan: any = [];
  selectNetwork: any = [];
  selectArea: any = [];
  planData: any;
  networkData: any;
  coverageList: any;
  IsFiltering: Boolean = false;
  copayList: any;
  selectCopay: any = [];
  advanceFilterForm: any;
  stateRecord: any = [];
  selectedPlan = new FormControl();
  selectedNetwork = new FormControl();
  selectedCoverage = new FormControl();
  selectedCopay = new FormControl();
  plansParentCheckbox = false;
  networkParentCheckbox = false;
  coverageParentCheckbox = false;
  copayParentCheckbox = false;

  //Bar Chart Configuration
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [{ data: [], fill: false }];

  dateMoment = moment();
  // Bar Chart Configuration Ends
  constructor(
    private quotesService: QuotesService,
    private userService: UserService,
    private authService: AuthenticationService,
    private custSer: CustomersService,
    private cdr: ChangeDetectorRef,
    private orgServ: OrgserviceService,
    private compserve: CompanyService,
    private toaster: ToastrService
  ) {}
  ngOnInit(): void {
    this.custSer.setLogoValue(false);
    this.custSer.setSpinnerInfo(true);
    this.getAnlyticsData();

    //get org list for filter by organisation
    this.orgServ.getItem().subscribe(
      (res: any) => {
        // this.organisationList = res;
        this.organisationList = res.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
      },
      (err) => {
        this.toaster.error(err.message);
      }
    );

    //get list of advisors
    this.userService.getUser().subscribe(async (res: any) => {
      this.quoteUsers = res;
      this.usersList = this.quoteUsers.filter(
        (u: any) => u?.firstName != undefined
      );
      this.usersList = this.usersList.sort((a: any, b: any) =>
        a.firstName.localeCompare(b.firstName)
      );
    });

    //get list of insurers
    this.compserve
      .getcompanydetailAlongwithPlans()
      .subscribe((response: any) => {

      this.compdata = response.sort((a: any, b: any) => {
        if (a.updatedName && b.updatedName)
          return a.updatedName > b.updatedName ? 1 : -1;
        else if (a.updatedName && b.name)
          return a.updatedName > b.name ? 1 : -1;
        else if (a.name && b.updatedName)
          return a.name > b.updatedName ? 1 : -1;
        else return a.name > b.name ? 1 : -1;
      });

      });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  //filter Insurers by organisation
  selectOrgs(value: any) {
    let arr = [...this.organisationList.map((item) => item.name), 'all'];
    if (
      (value == 'all' || this.selectOrg.length == arr.length -1) &&
      this.selectOrg.includes(value)
    )
      this.selectOrg = arr;
    else if (value == 'all' && this.selectOrg.length > 1)
      this.selectOrg = [];
    else if (value != 'all' && this.selectOrg.includes('all'))
      this.selectOrg = this.selectOrg.filter((v: any) => v != 'all');
    this.filters = {
      ...this.filters,
      orgs: this.selectOrg,
    };
  }

  //filter Insurers by advisors
  changeUsers(value: any) {
    let arr = [...this.usersList.map((item: any) => item.email), 'all'];
    if (
      (value == 'all' || this.selectUsers.length == arr.length -1) &&
      this.selectUsers.includes(value)
    )
      this.selectUsers = arr;
    else if (value == 'all' && this.selectUsers.length > 1)
      this.selectUsers = [];
    else if (value != 'all' && this.selectUsers.includes('all'))
      this.selectUsers = this.selectUsers.filter((v: any) => v != 'all');
    this.filters = {
      ...this.filters,
      users: this.selectUsers,
    };
  }

  //filter by insurer
  changeInsurer(value: any) {
    this.custSer.setSpinnerInfo(true);   
    let arr = [...this.compdata.map((item: any) => item._id), 'all'];
    if (
      (value == 'all' || this.selectInsurer.length == arr.length -1) &&
      this.selectInsurer.includes(value)
    )
      this.selectInsurer = arr;
    else if (value == 'all' && this.selectInsurer.length > 1)
      this.selectInsurer = [];
    else if (value != 'all' && this.selectInsurer.includes('all'))
      this.selectInsurer = this.selectInsurer.filter((v: any) => v != 'all');

    this.resetFields();
    const selectedcomp = this.selectInsurer
    this.compserve
      .getCompResidencies(selectedcomp)
      .subscribe((res: any) => {
        this.countryList = res;
        this.custSer.setSpinnerInfo(false);
      });
  }

  //select residenct and get plans
  changeResidency(value: any) {
    this.selectResidency = value.trim();

    this.selectPlan = [];
    this.selectNetwork = [];
    this.selectArea = [];
    this.selectCopay = [];

    let getPlanBody = {
      insurerId: this.selectInsurer,
      type: 'planname',
      clientResidency: this.selectResidency,
    };

    this.quotesService
      .getPlansDistinctInfo(getPlanBody)
      .then((data: any = []) => {
        data = this.formatDropDownData(data)
        this.planData = data;
        this.selectedPlan.setValue(data[0]?.plans)
        this.custSer.setSpinnerInfo(false);
      })
      .catch((error) => {
        this.custSer.setSpinnerInfo(false);
        this.toaster.error(error.message);
        if (error.statusText == 'Unauthorized' && error.status == '401') {
          this.authService.logout();
        }
      });
  }

  //change plan and get plan's network
  changePlan(event: any, name: any) {
    if (event.checked) {
      this.selectPlan.push({data: name, checked: true});
      const planNames = this.selectPlan.map((a: any) => a.data)
      this.selectedPlan.setValue(planNames);
    } else {
      this.selectPlan = this.selectPlan.filter((x: any) => x.data !== name);
      this.selectedPlan.setValue(this.selectedPlan.value.filter((x: any) => x.data !== name));
    }

    this.planData = this.planData.map((p: any) => {
      let planD = p.names.map((d: any) => {
        return {
          data: d.data,
          checked: d.data == name ? event.checked ? true : false : d.checked
        }
      })

      return {
        name: p.name,
        checked: p.checked,
        names: planD
      }
    })

    const isAllTrue = this.planData.find((plan: any) => plan.names.some((i: any) => i.checked === false));
    isAllTrue ? this.plansParentCheckbox = false : this.plansParentCheckbox = true

    this.custSer.setSpinnerInfo(true);

    if(!this.selectInsurer) {
      alert("Please select Insurer!");
      this.selectInsurer = []
      return
    }

    this.getNetworks()
  }

  getNetworks() {
    const planNames = this.selectPlan.map((a: any) => a.data)    
    let getNetworkBody = {
      insurerId: this.selectInsurer,
      planName1: planNames,
      type: 'network',
      clientResidency: this.selectResidency,
    };

    this.quotesService
      .getPlansDistinctInfo(getNetworkBody)
      .then((data: any = []) => {
        data = this.formatDropDownData(data)

        this.selectNetwork = [];
        this.selectArea = [];
        this.networkData = []
        this.networkData = data;
        this.selectedNetwork.setValue(data[0]?.plans)
        this.custSer.setSpinnerInfo(false);
      })
      .catch((error) => {
        console.log('error >> ', error);
        this.toaster.error(error.message);
        this.custSer.setSpinnerInfo(false);
        if (error.statusText == 'Unauthorized' && error.status == '401') {
          this.authService.logout();
        }
      });
  }

  //change network and get plan's network coverage
  changeNetwork(event: any, name: any) {
    if (event.checked) {
      this.selectNetwork.push({data: name, checked: true});
      const networkNames = this.selectNetwork.map((a: any) => a.data)

      this.selectedNetwork.setValue(networkNames);
    } else {
      this.selectNetwork = this.selectNetwork.filter((x: any) => x.data !== name);
      this.selectedNetwork.setValue(this.selectedNetwork.value.filter((x: any) => x.data !== name));
    }


    this.networkData = this.networkData.map((p: any) => {
      let planD = p.names.map((d: any) => {
        return {
          data: d.data,
          checked: d.data == name ? event.checked ? true : false : d.checked
        }
      })

      return {
        name: p.name,
        checked: p.checked,
        names: planD
      }
    })
    const isAllTrue = this.networkData.find((plan: any) => plan.names.some((i: any) => i.checked === false));
    isAllTrue ? this.networkParentCheckbox = false : this.networkParentCheckbox = true

    this.custSer.setSpinnerInfo(true);
    // this.selectNetwork = 1;

    if(!this.selectInsurer) {
      alert("Please select Insurer!");
      this.selectInsurer = []
      return
    }

    if (!this.selectPlan) {
      alert('Please select Plan name!');
      this.selectInsurer = [];
      return;
    }

    this.getInsurerArea()    
  }

  getInsurerArea() {
    const planNames = this.selectPlan.map((a: any) => a.data)
    const networkNames = this.selectNetwork.map((a: any) => a.data)
    let getPlanBody = {
      insurerId: this.selectInsurer,
      planName1: planNames,
      planName2: networkNames,
      type: 'insurerArea',
      clientResidency: this.selectResidency,
    };

    this.quotesService
      .getPlansDistinctInfo(getPlanBody)
      .then((data: any = []) => {
        data = this.formatDropDownData(data)

        this.coverageList = []
        this.coverageList = data;
        this.selectedCoverage.setValue(data[0]?.plans)
        this.custSer.setSpinnerInfo(false);
      })
      .catch((error) => {
        this.toaster.error(error.message);
        this.custSer.setSpinnerInfo(false);
        if (error.statusText == 'Unauthorized' && error.status == '401') {
          this.authService.logout();
        }
      });
  }

  //get insurer on the basis of coverage
  changeArea(event: any, name: any) {
    if (event.checked) {
      this.selectArea.push({data: name, checked: true});
      const areaNames = this.selectArea.map((a: any) => a.data)
      this.selectedCoverage.setValue(areaNames);
    } else {
      this.selectArea = this.selectArea.filter((x: any) => x.data !== name);
      this.selectedCoverage.setValue(this.selectedCoverage.value.filter((x: any) => x.data !== name));
    }

    this.coverageList = this.coverageList.map((p: any) => {
      let planD = p.names.map((d: any) => {
        return {
          data: d.data,
          checked: d.data == name ? event.checked ? true : false : d.checked
        }
      })

      return {
        name: p.name,
        checked: p.checked,
        names: planD
      }
    })

    const isAllTrue = this.coverageList.find((plan: any) => plan.names.some((i: any) => i.checked === false));
    isAllTrue ? this.coverageParentCheckbox = false : this.coverageParentCheckbox = true

    this.custSer.setSpinnerInfo(true);
    this.getCopays()
    
  }

  getCopays() {
    const planNames = this.selectPlan.map((a: any) => a.data)
    const networkNames = this.selectNetwork.map((a: any) => a.data)
    const coverageNames = this.selectArea.map((a: any) => a.data)
    let getPlanBody = {
      insurerId: this.selectInsurer,
      planName1: planNames,
      planName2: networkNames,
      type: 'copay',
      insurerArea: coverageNames,
      clientResidency: this.selectResidency,
    };

    this.quotesService
      .getPlansDistinctInfo(getPlanBody)
      .then((data: any = []) => {
        data = this.formatDropDownData(data)
        this.copayList = []
        this.copayList = data;
        this.selectedCopay.setValue(data[0]?.plans)
        this.custSer.setSpinnerInfo(false);
      })
      .catch((error) => {
        this.toaster.error(error.message);
        this.custSer.setSpinnerInfo(false);
        if (error.statusText == 'Unauthorized' && error.status == '401') {
          this.authService.logout();
        }
      });
  }

  changeCopay(event: any, name: any) {   
    if (event.checked) {
      this.selectCopay.push({data: name, checked: true});
      const copayNames = this.selectCopay.map((a: any) => a.data)
      this.selectedCopay.setValue(copayNames);
    } else {
      this.selectCopay = this.selectCopay.filter((x: any) => x.data !== name);
    }

    this.copayList = this.copayList.map((p: any) => {
      let planD = p.names.map((d: any) => {
        return {
          data: d.data,
          checked: d.data == name ? event.checked ? true : false : d.checked
        }
      })

      return {
        name: p.name,
        checked: p.checked,
        names: planD
      }
    })
    const isAllTrue = this.copayList.find((plan: any) => plan.names.some((i: any) => i.checked === false));
    isAllTrue ? this.copayParentCheckbox = false : this.copayParentCheckbox = true
  }

  //filter insurers by plan name, network, converage
  filterInsurers(filters: any) {
    this.custSer.setSpinnerInfo(true);
    this.quotesService
      .getAnalyticquoteDataFilter(filters)
      .then((data: any = []) => {
        if (!data?.length) {
          this.toaster.success('No data found for selected Insurer or Organizations!');
        }

        let processData: any = []
        if(data[0]?.orgnization) {
          data.map((d: any) => {
            d.insurers.map((countData: any) => {
              if(!countData) return 
              countData = JSON.parse(JSON.stringify(countData))
              processData.push({...countData, ...{orgName: d.orgnization}})
            })
          })
          data = processData
        }

        data = data.filter(Boolean);

        this.orgData1 = data;
        let arr = this.orgData1.filter(
          (x: any) => x._id.name !== undefined && x._id.name != null && x.companyDetails.length
        );

        this.quotesLabel = arr.map((x: any) => this.selectPlan?.length ?
         `${ x.companyDetails[0]?.updatedName ? `${x.orgName ? x.orgName : ""} - ${x.companyDetails[0]?.updatedName}` : `${x.orgName ? x.orgName : ""} - ${x.companyDetails[0]?.name}`} - ${x._id.planName1}` 
        : x.companyDetails[0]?.updatedName ? `${x.orgName ? x.orgName : ""} - ${x.companyDetails[0]?.updatedName}` : `${x.orgName ? x.orgName : ""} - ${x.companyDetails[0]?.name}`);
        this.quotesValue = arr.map((x: any) => x.count);
        this.barChartData[0].data = this.quotesValue;
        this.barChartLabels = this.quotesLabel;
        this.custSer.setSpinnerInfo(false);
        this.IsFiltering = false;
        this.selectInsurer = [];
        this.resetFields()
      })
      .catch((error) => {
        console.log('error >> ', error);
        this.toaster.error(error.message);
        this.IsFiltering = false;
        this.custSer.setSpinnerInfo(false);
        if (error.statusText == 'Unauthorized' && error.status == '401') {
          this.authService.logout();
        }
      });
  }

  advanceFilter() {
    this.IsFiltering = true;
  }

  onClickCancel() {
    this.IsFiltering = false;
  }

  //filter insurers on click on filter button
  filterInsurerSubmit() {
    let companyData: any = [];
    this.selectInsurer?.map((data: any) => {
      if(data == 'all') return
      companyData.push(this.compdata.filter((com: any) => com._id == data)[0].name)
    })

    const planNames = this.selectPlan.map((a: any) => a.data)
    const networkNames = this.selectNetwork.map((a: any) => a.data)
    const coverageNames = this.selectArea.map((a: any) => a.data)
    const copayNames = this.selectCopay.map((a: any) => a.data)

    this.dateQuotesArr = [];
    let fromDate = moment(this.fromDate).format('YYYY-MM-DD');
    var d = new Date(this.toDate);
    d.setDate(d.getDate() + 1);
    let toDate = moment(d).format('YYYY-MM-DD');

    if (this.fromDate == undefined && this.toDate == undefined && !this.selectInsurer?.length && !this.selectOrg?.length && !this.selectUsers?.length) {
      if (fromDate > toDate) {
        this.toaster.warning('Please select the correct date range !');
        return
      } else {
        this.toaster.warning('Please select any filter!');
        return
      }
    } else if (this.fromDate != undefined && this.toDate == undefined) {
      this.toaster.warning('Please select the End Date !');
      return
    } else if (this.fromDate == undefined && this.toDate != undefined) {
      this.toaster.warning('Please select the Start Date !');
      return
    } else if (this.fromDate != undefined && this.toDate != undefined) {
      if (fromDate > toDate) {
        this.toaster.warning('Please select the correct date range !');
        return
      } else {
        this.filters = {
          ...this.filters,
          fromDate,
          toDate,
        };

      }
    }

    this.filters = {
      ...this.filters,
      insurer: companyData,
      residency: this.selectResidency,
      planName: planNames,
      planNetwork: networkNames,
      planCoverage: coverageNames,
      coPay: copayNames
    };

    this.filterInsurers(this.filters);
  }

  //get all inurers quotes
  getAnlyticsData() {
    this.custSer.setSpinnerInfo(true);
    this.quotesService
      .getAnalyticquoteData()
      .then((data) => {
        this.orgData1 = data;

        let arr = this.orgData1.filter(
          (x: any) => x._id.name !== undefined && x._id.name != null
        );

        this.quotesLabel = arr.map((x: any) => this.selectPlan?.length ? `${x.companyDetails[0]?.updatedName ? x.companyDetails[0]?.updatedName : x.companyDetails[0]?.name} - ${x._id.planName1}` 
        : x.companyDetails[0]?.updatedName ? x.companyDetails[0]?.updatedName : x._id.name);
        this.quotesValue = arr.map((x: any) => x.count);
        this.barChartData[0].data = this.quotesValue;
        this.barChartLabels = this.quotesLabel;
        this.custSer.setSpinnerInfo(false);
      })
      .catch((error) => {
        this.custSer.setSpinnerInfo(false);
        this.toaster.error(error.message);
      });
  }

  //reset filters and selected feilds on reseting
  resetFilters() {
    this.getAnlyticsData();
    this.filters = {};
    this.selectOrg = [];
    this.selectUsers = [];
    this.selectInsurer = [];
    this.fromDate = undefined,
    this.toDate = undefined,
    this.resetFields()
  }

  resetFields() {
    this.selectPlan = [];
    this.selectNetwork = [];
    this.selectArea = [];
    this.selectResidency = [];
    this.selectCopay = [];
    this.countryList = [];
    this.planData = []
    this.networkData = []
    this.coverageList = []
    this.copayList = []
    this.selectedPlan.setValue(null)
    this.selectedNetwork.setValue(null)
    this.selectedCoverage.setValue(null)
    this.selectedCopay.setValue(null)
  }

  getcurrentDate(val: any) {
    if (val) {
      var d = val.split('-');
      var month = '' + d[1];
      var day;
      var year;
      (day = '' + d[0]), (year = d[2]);

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    } else {
      return val;
    }
  }

  togglePlansParent(event: any, group: any) {
      group.checked = event.checked;
      let selectedData = this['selectedPlan'].value;
      selectedData = selectedData ? selectedData : [];
      
      if (event.checked) {
        selectedData.push(...group.names);
      } else {
        group.names.forEach((x: any) => {
          if (selectedData.indexOf(x.data) > -1) {
            selectedData.splice(selectedData.indexOf(x.data), 1);
          }
        });
      }
      this['selectedPlan'].setValue(selectedData);
      if (!event.checked) {
        const filtered = this['selectedPlan'].value.filter(function(cv: any){
            return !group.names.find(function(e: any){
                return e.id == cv.id;
            });
        })
        this['selectedPlan'].setValue(filtered);
      }

      let plans = group.names.map((data: any) => {
        return {
          data: data.data,
          checked: event.checked ? true : false
        }
      })
      const index = this.planData.findIndex((d: any) => d.name == group.name)
      this.planData[index].names = plans

      this.selectPlan = this['selectedPlan'].value;

      const planNames = this.selectPlan.map((a: any) => a.data)
      this['selectedPlan'].setValue(planNames);
      const allDeseleted = this.planData.find((data: any) => data.checked == false)
      allDeseleted ? this.plansParentCheckbox = false : this.plansParentCheckbox = true
      this.getNetworks()
  }

  // togglePlansParent(event: any, group: any) {
  //   group.checked = event.checked;
  //   let selectedPlan = this.selectedPlan.value;
  //   selectedPlan = selectedPlan ? selectedPlan : [];
  //   if (event.checked) {
  //     selectedPlan.push(...group.names);
  //   } else {
  //     group.names.forEach((x: string) => {
  //       if (selectedPlan.indexOf(x) > -1) {
  //         selectedPlan.splice(selectedPlan.indexOf(x), 1);
  //       }
  //     });
  //   }
  //   this.selectedPlan.setValue(selectedPlan);
  //   if (!event.checked) {
  //     this.selectedPlan.setValue(
  //       this.selectedPlan.value.filter((x: any) => !x.includes(group.names))
  //     );
  //   }
  //   this.selectPlan = this.selectedPlan.value;
  //   this.getNetworks()
  // }

  toggleNetworkParent(event: any, group: any) {
    group.checked = event.checked;
    let selectedNetwork = this.selectedNetwork.value;
    selectedNetwork = selectedNetwork ? selectedNetwork : [];
    if (event.checked) {
      selectedNetwork.push(...group.names);
    } else {
      group.names.forEach((x: any) => {
        if (selectedNetwork.indexOf(x.data) > -1) {
          selectedNetwork.splice(selectedNetwork.indexOf(x.data), 1);
        }
      });
    }
    this.selectedNetwork.setValue(selectedNetwork);
    if (!event.checked) {
      const filtered = this['selectedNetwork'].value.filter(function(cv: any){
        return !group.names.find(function(e: any){
            return e.id == cv.id;
        });
    })
      this.selectedNetwork.setValue(filtered);
    }
    let plans = group.names.map((data: any) => {
      return {
        data: data.data,
        checked: event.checked ? true : false
      }
    })
    const index = this.networkData.findIndex((d: any) => d.name == group.name)
    this.networkData[index].names = plans
    this.selectNetwork = this.selectedNetwork.value;
    const planNames = this.selectNetwork.map((a: any) => a.data)
      this['selectedNetwork'].setValue(planNames);
    const allDeseleted = this.networkData.find((data: any) => data.checked == false)
    allDeseleted ? this.networkParentCheckbox = false : this.networkParentCheckbox = true
    this.getInsurerArea() 
  }

  toggleInsurerParent(event: any, group: any) {
    group.checked = event.checked;
    let selectedCoverage = this.selectedCoverage.value;
    selectedCoverage = selectedCoverage ? selectedCoverage : [];
    if (event.checked) {
      selectedCoverage.push(...group.names);
    } else {
      group.names.forEach((x: any) => {
        if (selectedCoverage.indexOf(x.data) > -1) {
          selectedCoverage.splice(selectedCoverage.indexOf(x.data), 1);
        }
      });
    }
    this.selectedCoverage.setValue(selectedCoverage);
    if (!event.checked) {
      const filtered = this['selectedCoverage'].value.filter(function(cv: any){
        return !group.names.find(function(e: any){
            return e.id == cv.id;
        });
    })
      this.selectedCoverage.setValue(filtered);
    }
    let plans = group.names.map((data: any) => {
      return {
        data: data.data,
        checked: event.checked ? true : false
      }
    })
    const index = this.coverageList.findIndex((d: any) => d.name == group.name)
    this.coverageList[index].names = plans
    this.selectArea = this.selectedCoverage.value;
    const planNames = this.selectArea.map((a: any) => a.data)
      this['selectedCoverage'].setValue(planNames);
    const allDeseleted = this.coverageList.find((data: any) => data.checked == false)
    allDeseleted ? this.coverageParentCheckbox = false : this.coverageParentCheckbox = true
    this.getCopays()
  }

  toggleCopayParent(event: any, group: any) {
    group.checked = event.checked;
    let selectedCopay = this.selectedCopay.value;
    selectedCopay = selectedCopay ? selectedCopay : [];
    if (event.checked) {
      selectedCopay.push(...group.names);
    } else {
      group.names.forEach((x: any) => {
        if (selectedCopay.indexOf(x.data) > -1) {
          selectedCopay.splice(selectedCopay.indexOf(x.data), 1);
        }
      });
    }
    this.selectedCopay.setValue(selectedCopay);
    if (!event.checked) {
      if (!event.checked) {
        const filtered = this['selectedCopay'].value.filter(function(cv: any){
          return !group.names.find(function(e: any){
              return e.id == cv.id;
          });
      })
      
      this.selectedCopay.setValue(filtered);
    }
    }

    let plans = group.names.map((data: any) => {
      return {
        data: data.data,
        checked: event.checked ? true : false
      }
    })
    const index = this.copayList.findIndex((d: any) => d.name == group.name)
    this.copayList[index].names = plans
    this.selectCopay = this.selectedCopay.value;
    const planNames = this.selectCopay.map((a: any) => a.data)
      this['selectedCopay'].setValue(planNames);
    const allDeseleted = this.copayList.find((data: any) => data.checked == false)
    allDeseleted ? this.copayParentCheckbox = false : this.copayParentCheckbox = true
  }

  //modify distinct API response for drop-downs
  formatDropDownData(data: any) {
    return data.map((plan: any) => {
      let result = this.compdata.filter((comp: any) => comp._id == plan.insurerId)
      let plans = plan.plans.map((data: any) => {
        return {
          data: data,
          checked: false
        }
      })
      return {
        name: result[0]?.updatedName ? result[0]?.updatedName : result[0].name,
        checked: false,
        names: plans,
        selected: false
      }
    })
  }

  selectParentCheckbox(event: any, filterBy: any) {
    const e = event;
    if(filterBy == 'plan') {
      const selectField = this['selectedPlan'];
      const selectFieldData = this.planData;
      this.plansParentCheckbox = true
      this.selectPlan = this.updatetParentCheckboxData(e, selectField, selectFieldData);   
      const planNames = this.selectPlan.map((a: any) => a.data)
      this['selectedPlan'].setValue(planNames); 
      this.getNetworks();
    } else if(filterBy == 'network') {
      const selectField = this['selectedNetwork'];
      const selectFieldData = this.networkData;
      this.networkParentCheckbox = true
      this.selectNetwork = this.updatetParentCheckboxData(e, selectField, selectFieldData);    
      const planNames = this.selectNetwork.map((a: any) => a.data)
      this['selectedNetwork'].setValue(planNames);
      this.getInsurerArea();
    } else if(filterBy == 'coverage') {
      const selectField = this['selectedCoverage'];
      const selectFieldData = this.coverageList;
      this.coverageParentCheckbox = true
      this.selectArea = this.updatetParentCheckboxData(e, selectField, selectFieldData);    
      const planNames = this.selectArea.map((a: any) => a.data)
      this['selectedCoverage'].setValue(planNames);
      this.getCopays();
    } else if(filterBy == 'copay') {
      const selectField = this['selectedCopay'];
      const selectFieldData = this.copayList;
      this.copayParentCheckbox = true
      this.selectCopay = this.updatetParentCheckboxData(e, selectField, selectFieldData);  
      const planNames = this.selectCopay.map((a: any) => a.data)
      this['selectedCopay'].setValue(planNames);  
    }
  }

  updatetParentCheckboxData(event: any, selectField: any, selectFieldData: any) {
    let selectedData = selectField.value;
    selectedData = selectedData ? selectedData : [];
    let result:any = []
    selectFieldData.forEach((d: any) => {
      if (event.checked) {
        d.checked = true;
        selectedData.push(...d.names);
      } else if (!event.checked) {
        d.checked = false;
        d.names.forEach((x: any) => {
          if (selectedData.indexOf(x.data) > -1) {
            selectedData.splice(selectedData.indexOf(x.data), 1);
          }
        });
      }
      selectField.setValue(selectedData); 
      if (!event.checked) {

        const filtered = selectedData.filter(function(cv: any){
          return !d.names.find(function(e: any){
              return e.id == cv.id;
          });
      })

        selectField.setValue(filtered);
      }

      let plans = d.names.map((data: any) => {
        return {
          data: data.data,
          checked: event.checked ? true : false
        }
      })
      const index = selectFieldData.findIndex((details: any) => details.name == d.name)
      selectFieldData[index].names = plans

      result.push(selectField.value)
    });



    return result[0]
  }
}
